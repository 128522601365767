import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import Login from "../pages/Login";
import Childlist from "../pages/Childlist";
import Dashboard from "../pages/Dashboard";
import MyChild from "../pages/MyChild";
import Impressum from "../pages/Impressum";
import Datenschutz from "../pages/Datenschutz";
import Nutzungsbedingungen from "../pages/Nutzungsbedingungen";
import Whiteboard from "../pages/Whiteboard";
import Profile from "../pages/Profile";
import Calendar from "../pages/Calendar";
import CalendarItemView from "../pages/CalendarItemView";
import More from "../pages/More";
import Beirat from "../pages/Beirat";
import Downloads from "../pages/Downloads";
import MyCareHours from "../pages/MyCareHours";
import MyKita from "../pages/MyKita";

export default function Routes() {
    return (
        <Switch>

            <Route path="/kinder" exact component={Childlist} />
            <Route path="/kind" exact component={MyChild} />
            <Route path="/impressum" exact component={Impressum} />
            <Route path="/kalender" exact component={Calendar} />
            <Route path="/kalender/:id" exact component={CalendarItemView} />
            <Route path="/datenschutz" exact component={Datenschutz} />
            <Route path="/nutzungsbedingungen" exact component={Nutzungsbedingungen} />
            <Route path="/whiteboard" exact component={Whiteboard} />
            <Route path="/mehr" exact component={More} />
            <Route path="/beirat" exact component={Beirat} />
            <Route path="/downloads" exact component={Downloads} />
            <Route path="/stunden" exact component={MyCareHours} />
            <Route path="/kita" exact component={MyKita} />
            <Route path="/profil" exact component={Profile} /><Route path="/" exact component={localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != '' ? Dashboard : Login} />
            <Route path="/" exact component={localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != '' ? Dashboard : Login} />
            {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
            <Route component={localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != '' ? Dashboard : Login} />
        </Switch>
    );
}

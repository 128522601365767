
/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {Component, Fragment} from 'react';
import axios from 'axios';
import Moment from "moment";
import { Button } from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

class CalendarItemView extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={ isLoading: true, token: localStorage.getItem('access_token'), customer: localStorage.getItem('customer'), calendar: [], selecteddats: [], erinnern: '', errors: []};

    }

    componentDidMount(){

        const onSuccess = ({data}) => {
            console.log(data);
            this.setState({isLoading: false, calendar: data, erinnern: (data.reminder == null) ? "" : data.reminder.reminder_value.toString()});

        };

        const onFailure = error => {
            console.log(error);
            this.setState({isLoading: false});
        };

        axios.get('https://app.kitaleicht.com/api/users/calendar/customer/' + this.state.customer + '/' + this.props.match.params.id, {
            headers: {
                'Authorization': `Bearer ${this.state.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(onSuccess)
            .catch(onFailure);

    }

    onValueChangeerinnern (value) {
        this.setState({
            erinnern : value
        });
        this.save(value);
    }

    save(value) {

        axios.post('https://app.kitaleicht.com/api/users/calendar/' + this.props.match.params.id + '/reminder',
            {
                'reminder_value': value
            },{
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((success) => {
                //this.setState({})
            })
            .catch((error => {
                if(error.response.status===500){
                    alert("Fehler 500 - ein unbekanntes Problem. Wende  dich an den technischen Support.");
                }else{
                    let errors = []

                    Object.keys(error.response.data.message).map(function(key, val) {

                        errors.push(error.response.data.message[key][0]);
                    });
                    this.setState({errors: errors});
                }
            }));
    }

    render() {
        const {calendar, errors} = this.state;

        const status = (status) => {
            //Moment.locale('de')

            if(status) {

                    const onSuccess = ({data}) => {
                       // this.props.navigation.goBack()
                        this.state.calendar.myallocations.confirmed =  1;
                        this.setState({calendar: this.state.calendar});

                    };

                    const onFailure = error => {
                        console.log(error);
                        //this.setState({errors: error.response.data, isLoading: false});
                    };

                    axios.get('https://app.kitaleicht.com/api/users/calendar/customer/' + this.state.customer + '/' + this.props.match.params.id+'/success', {
                        headers: {
                            'Authorization': `Bearer ${this.state.token}`,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(onSuccess)
                        .catch(onFailure);

            }else {


                const onSuccess = ({data}) => {
                    window.history.back();
                };

                const onFailure = error => {
                    console.log(error);
                    //this.setState({errors: error.response.data, isLoading: false});
                };

                axios.get('https://app.kitaleicht.com/api/users/calendar/customer/' + this.state.customer + '/' + this.props.match.params.id+'/cancel', {
                    headers: {
                        'Authorization': `Bearer ${this.state.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                    .then(onSuccess)
                    .catch(onFailure);

            }
        }

        if(this.state.isLoading) {
            return (
                <Fragment>
                    Laden
                </Fragment>

            )
        }
            return (
                <Fragment>

                    <div
                        contentInsetAdjustmentBehavior="automatic"
                        style={{padding: 20, margin: 0, backgroundColor: '#fff'}}
                    >
                        {
                            (calendar.length == 0) ? null
                                :
                                <div>


                                    <h2 style={{fontWeight: '600', marginTop: 10}}>{calendar.items.title}</h2>
                                    <span style={{marginTop: 10, color: '#666'}}>{calendar.items.text}</span>

                                    <div style={{borderBottomWidth: 0, marginTop: 20, marginLeft: 0, marginBottom: 0}}>
                                        <div style={{flexDirection: 'row', display: 'flex', borderBottomWidth: 0, marginLeft: 0, marginBottom: 0}}>
                                            <img
                                                style={{width: 26, height: 26}}
                                                src={("/assets/icon/kalender_colour.png")}
                                            />
                                            <h1 style={{color: '#8089C3', marginLeft: 10, fontSize: 18, fontWeight: '400', marginTop: 3}}>{Moment(calendar.items.date).format('DD.MM.Y')}</h1>
                                        </div>
                                    </div>
                                    <div style={{borderBottomWidth: 0, marginLeft: 0, marginTop: 0}}>
                                        <div style={{flexDirection: 'row', display: 'flex', borderBottomWidth: 0, marginLeft: 0, marginBottom: 0}}>
                                            <img
                                                style={{width: 26, height: 26}}
                                                src={("/assets/icon/clock.png")}
                                            />
                                            <h1 style={{color: '#8089C3', marginLeft: 10, fontSize: 18, fontWeight: '400', marginTop: 0}}>{calendar.items.time.substr(0,calendar.items.time.length - 3)} Uhr</h1>
                                        </div>
                                    </div>

                                    <h2 style={{fontWeight: '600', marginTop: 20, marginBottom: 10}}>Details</h2>
                                    <div>

                                        <p style={{ color: '#666'}}>{calendar.items.location.name}</p>
                                        <p style={{ color: '#666'}}>{calendar.items.location.street}</p>
                                        <p style={{ color: '#666'}}>{calendar.items.location.zip} {calendar.items.location.city}</p>

                                    </div>

                                    {
                                        (calendar.myallocations.confirmed != 1)
                                            ?
                                            <div style={{flexDirection: 'row', display: 'flex',justifyContent: 'center', alignItems: 'center', margin: 'auto', marginTop: 50}}>
                                                <Button warning onPress={() => status(1)} rounded style={{backgroundColor: '#81CCCE', marginRight: '2%',width: '48%', justifyContent: 'center', alignItems: 'center'}}>
                                                    <span style={{color: '#fff'}}>Zusagen</span>
                                                </Button>

                                                <Button warning onPress={() => status(0)} rounded style={{backgroundColor: 'darkred', marginLeft: '2%', width: '48%', justifyContent: 'center', alignItems: 'center'}}>
                                                    <span style={{color: '#fff'}}>Absagen</span>
                                                </Button>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        (calendar.myallocations.confirmed == 1)
                                        ?

                                           <div style={{marginTop: 20}}>
                                                <p>vorher Erinnern (Optional)</p>
                                                <div style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, color: '#000'}}>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={this.state.erinnern}
                                                        onChange={this.onValueChangeerinnern.bind(this)}
                                                    >
                                                        <MenuItem value={''}>Bitte wählen</MenuItem>
                                                        <MenuItem label='Bitte wählen' value='' />
                                                        <MenuItem label='5 min' value='5' />
                                                        <MenuItem label='15 min' value='15' />
                                                        <MenuItem label='30 min' value='30' />
                                                        <MenuItem label='1 Stunde' value='60' />
                                                        <MenuItem label='2 Stunde' value='120' />
                                                        <MenuItem label='1 Tag' value='1440' />
                                                        <MenuItem label='2 Tage' value='2880' />
                                                    </Select>
                                                </div>
                                            </div>

                                        :
                                        null}

                                    {
                                        errors.length == 0 ? null
                                            :
                                            <div stlye={{
                                                width: '100%',
                                                marginLeft: 0,
                                                marginRight: 0,
                                                backgroundColor: '#B22222',
                                                padding: 10
                                            }}>
                                                {
                                                    errors.map((tile, key) => (
                                                        <div stackedLabel style={{borderBottomWidth: 0, marginTop: 5,padding: 5, paddingTop:0}}>
                                                            <p style={{color: '#B22222'}}>{tile}</p>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    }
                                </div>
                        }
                    </div>
                </Fragment>
            );

    }
};

export default CalendarItemView;

import React, {Component} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import logo from '../assets/kitaleicht_Logo.jpg'
import { Link as RouterLink } from 'react-router-dom';
import '../assets/header.css';

const MehrLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);
function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

class Header extends Component {

    state = {
        token: localStorage.getItem('access_token')
    };;


    render() {

        if(window.innerWidth > 0) {

            const flexContainer = {
                display: 'inline-flex',
                flexDirection: 'row',
                padding: 0,
            };

            const removeLogin = () => {
                localStorage.clear();
                window.location.href = '/';
            }

            return (
                <div className={"header"}>
                    <header>
                        <p>
                            <a href={'/'}><img src={logo} alt="Logo" className={"logo"} /></a>
                        </p>
                        {
                            localStorage.getItem('access_token') == null || localStorage.getItem('access_token') == ""
                                ?
                                ''
                                :
                                <div className={"header-sub"}>
                                    <List component="nav" aria-label="secondary mailbox folders" style={flexContainer}>
                                        <ListItem button component="a" href={'/'}>
                                            <ListItemText primary="Dashboard" />
                                        </ListItem>
                                        <ListItem button component="a" href={'/kind'}>
                                            <ListItemText primary="Mein Kind" />
                                        </ListItem>

                                        <ListItem button component="a" href={'/profil'}>
                                            <ListItemText primary="Mein Profil" />
                                        </ListItem>
                                        <ListItem button component="a" href={'/mehr'}>
                                            <ListItemText primary="Mehr" />
                                        </ListItem>
                                        <ListItemLink onClick={removeLogin}>
                                            <ListItemText primary="Abmelden" />
                                        </ListItemLink>
                                    </List>
                                </div>
                        }
                    </header>
                </div>
            );
        } else {
            return ;
        }
    }
}

export default (Header);

/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {Fragment, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardItem from '@material-ui/core/CardContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Todolist from "../components/modal/todolist";
import Absence from "../components/modal/absence";
import Collector from "../components/modal/collector";
import Board from "../components/modal/Board";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class Whiteboard extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state = {customer: (localStorage.getItem('customer')), group: JSON.parse(localStorage.getItem('group')).id, child: localStorage.getItem('child'), token: localStorage.getItem('access_token'), openboard: false, activeWhiteboardPage: 2, showprofile: false, image: "",isLoading: false, whiteboardManagement: [], whiteboard: []};
    }


    componentDidMount(){

        const onSuccess = ({data}) => {
          this.setState({isLoading: false, whiteboard: data.data.whiteboard, whiteboardManagement: data.data.whiteboard_public});
        };
        const onFailure = error => {
            console.log('whiteboard2', error);

            //this.setState({errors: error.response.data, isLoading: false});
        };

        axios.get('https://app.kitaleicht.com/api/users/whiteboard/customer/list/' + this.state.token+'?child='+this.state.child + '&group='+this.state.group, {
            headers: {
                'Authorization': `Bearer ${this.state.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(onSuccess)
            .catch(onFailure);

    }

    openBoard(item) {
        console.log(item);
        //this.props.navigation.navigate('GalleryItem', {id: item.id, title: item.name});
        this.setState({boarditem: item, openboard: true});
    }

    render() {
        const selectComponent = (activeWhiteboardPage) => () => this.setState({activeWhiteboardPage})

        const onModalBoardQr = () => {
            this.setState({openboard: false})
        }

        return (
            <Fragment>
               <div style={{backgroundColor: '#fff', paddingLeft: 10}}>

                   <div style={{marginTop: 30}}>
                       <div style={{borderBottomWidth: 0}}>
                           <div style={{float: 'left'}}>
                               <h1 style={{marginLeft: 0, fontSize: 22, fontWeight: '600'}}><img
                                   style={{width: 26, height: 26, marginRight: 8}}
                                   src={("/assets/icon/news-colour.png")}
                               /> Schwarzes Brett</h1>

                           </div>

                           <div style={{clear: 'both'}}></div>
                       </div>
                   </div>


                   {
                       (this.state.whiteboard.length == 0 && this.state.whiteboardManagement.length == 0)
                           ?
                           <div  style={{alignItems: 'center', justifyContent: 'center', paddingBottom: 10}}><div>Keine Nachrichten gefunden</div></div>
                           :
                           <div>
                               <Button first style={{padding: 0, textAlign: 'center',justifyContent: 'center',alignItems: 'center', width: '47%', color:(this.state.activeWhiteboardPage == 0) ? '#fff':'#8089C3',borderColor:(this.state.activeWhiteboardPage == 0) ? '#8089C3':'#eee',backgroundColor: (this.state.activeWhiteboardPage == 0) ? '#8089C3':'#eee'}} active={(this.state.activeWhiteboardPage == 0) ? true:false} onClick={() => this.setState({activeWhiteboardPage: 0})}>
                                   <p style={{textAlign: 'center',justifyContent: 'center',alignItems: 'center', color:(this.state.activeWhiteboardPage == 0) ? '#fff':'#8089C3'}}>Eltern</p>
                               </Button>
                               <Button last style={{padding: 0, textAlign: 'center',justifyContent: 'center',alignItems: 'center', width: '47%', color:(this.state.activeWhiteboardPage == 1) ? '#fff':'#8089C3',borderColor:(this.state.activeWhiteboardPage == 1) ? '#8089C3':'#eee',backgroundColor: (this.state.activeWhiteboardPage == 1) ? '#8089C3':'#eee'}} active={(this.state.activeWhiteboardPage == 1) ? true:false}  onClick={() => this.setState({activeWhiteboardPage: 1})}>
                                   <p style={{textAlign: 'center',justifyContent: 'center',alignItems: 'center', color:(this.state.activeWhiteboardPage == 1) ? '#fff':'#8089C3'}}>Allgemein</p>
                               </Button>
                               <div>
                                   <TabPanel value={this.state.activeWhiteboardPage} index={0} style={{padding: 0}}>
                                       {
                                           this.state.whiteboardManagement.length == 0 ? <div  style={{alignItems: 'center', justifyContent: 'center', paddingBottom: 10}}><div>Keine Nachrichten gefunden</div></div> :
                                               this.state.whiteboardManagement.map((item) =>
                                                   <div activeOpacity={0.9} onClick={() => this.openBoard(item)}>
                                                       <Card style={{borderColor: '#fff', marginBottom: 10, marginLeft: 0, marginRight: 15, borderWidth: 0, borderRadius: 10, padding: 0}}>

                                                           <CardItem style={{borderRadius: 20, shadowOpacity: 0,borderColor: '#fff', borderWidth: 0}}>
                                                               <div>

                                                                   <h2 style={{fontWeight: '600', fontSize: 18, marginBottom: 0}}>{item.subject}</h2>
                                                                   <div style={{color: '#bbb'}}>

                                                                   </div>
                                                                   <div style={{marginTop: 10}}>
                                                                       {item.text}
                                                                   </div>
                                                               </div>
                                                           </CardItem>

                                                           {
                                                               (item.image.url == null) ? null
                                                                   :
                                                                   <CardItem  style={{borderRadius: 20}}>

                                                                       <div style={{backgroundImage: 'url('+item.image.url+')', backgroundSize: 'cover', width: '100%', margin: 20, height: 200, flex: 1, borderRadius: 20}}></div>
                                                                   </CardItem>
                                                           }
                                                       </Card>
                                                   </div>
                                               )
                                       }
                                   </TabPanel>
                                   <TabPanel value={this.state.activeWhiteboardPage} index={1}>
                                       {
                                           this.state.whiteboard.length == 0 ? <div  style={{alignItems: 'center', justifyContent: 'center', paddingBottom: 10}}><div>Keine Nachrichten gefunden</div></div> :
                                               this.state.whiteboard.map((item) =>
                                                   <div activeOpacity={0.9} onClick={() => this.openBoard(item)}>
                                                       <Card style={{borderColor: '#fff', marginBottom: 10, marginLeft: 0, marginRight: 15, borderWidth: 0, borderRadius: 10, padding: 0}}>

                                                           <CardItem style={{borderRadius: 20, shadowOpacity: 0,borderColor: '#fff', borderWidth: 0}}>
                                                               <div>

                                                                   <h2 style={{fontWeight: '600', fontSize: 18, marginBottom: 0}}>{item.subject}</h2>
                                                                   <div style={{color: '#bbb'}}>

                                                                   </div>
                                                                   <div style={{marginTop: 10}}>
                                                                       {item.text}
                                                                   </div>
                                                               </div>
                                                           </CardItem>

                                                           {
                                                               (item.image.url == null) ? null
                                                                   :
                                                                   <CardItem  style={{borderRadius: 20}}>

                                                                       <div style={{backgroundImage: 'url('+item.image.url+')', backgroundSize: 'contain', width: '100%', margin: 20, height: 200, flex: 1, borderRadius: 20,backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>
                                                                   </CardItem>
                                                           }
                                                       </Card>
                                                   </div>
                                               )
                                       }
                                   </TabPanel>
                               </div>
                           </div>
                   }
               </div>
                {(this.state.openboard) ? <Board item={this.state.boarditem} onModalBoardQr={onModalBoardQr} modal={this.state.openboard}/> : null}
            </Fragment>
        );
    }
}


export default function(props) {

    return <Whiteboard {...props}/>;
}

/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {useEffect, useState} from "react";
import axios from "axios";
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardItem from '@material-ui/core/CardContent';
import Moment from "moment";
import Board from "../components/modal/Board";

import ChildProfile from "../components/modal/ChildProfile";
import AddAllergies from "../components/modal/addAllergies";
import AddDrugs from "../components/modal/addDrugs";
import Showtododetails from "../components/modal/showtododetails";
import Emergency_contacts from "../components/modal/emergency_contacts";
import Edit_emergency_contacts from "../components/modal/edit_emergency_contacts";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/de";

class MyChild extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={image: localStorage.getItem('profilimage'),token: localStorage.getItem('access_token'), dateTxt: 'Tag auswählen: heute',showCal: false, group: JSON.parse(localStorage.getItem('group')), edit_emergency_contacts: null, emergency_type: null, add_emergency_contacts: false, openchat: false, user: null, showtodo: false, tododetail: [], showadddrugs: false, showaddallergie: false, showprofile: false, openboard: false, boarditem:[], protocol: null, childNotice: [], eatingplan: [], childDiary: [], begin_date: new Date(), end_date: new Date(), modus: 'daily', isLoading: true, child: null, selectchild: localStorage.getItem('child'), todolist: []};
    }

    componentDidUpdate(prevProps) {
        console.log(prevProps.isFocused + ' '+ this.props.isFocused);

        if(!prevProps.isFocused && this.props.isFocused){
            this.loadDiary();
            this.loadChild();
        }
    }

    componentDidMount(){
        this.loadDiary();
        this.loadChild();
    }

    loadChild() {
        const onSuccess = ({data}) => {

            this.setState({
                child: data.data
            });
        };

        const onFailure = error => {
           // console.log('mychild',error.response);
            //this.setState({errors: error.response.data, isLoading: false});
        };
        console.log(this.state.token);
        axios.get('https://app.kitaleicht.com/api/users/my/child/' + this.state.selectchild, {
            headers: {
                'Authorization': `Bearer ${this.state.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(onSuccess)
                .catch(onFailure);

    }

    loadDiary() {
        const onSuccess = ({data}) => {
            this.setState({
                isLoading: false,
                todolist: data.data.todos,
                whiteboard: data.data.whiteboard,
                childDiary: data.data.childDiary,
                protocol: data.data.protocol,
                eatingplan: data.data.eatingplan,
                childNotice: data.data.childNotice
            });
        };

        const onFailure = error => {
            //this.setState({errors: error.response.data, isLoading: false});
        };


        axios.get('https://app.kitaleicht.com/api/users/diary/child/' + this.state.selectchild + '?from=' + Moment(this.state.begin_date).format('Y-MM-DD') + '&til=' + Moment(this.state.end_date).format('Y-MM-DD'), {
            headers: {
                'Authorization': `Bearer ${this.state.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(onSuccess)
            .catch(onFailure);

    }

    openBoard(item) {
        console.log(item);
        //this.props.navigation.navigate('GalleryItem', {id: item.id, title: item.name});
        this.setState({boarditem: item, openboard: true});
    }

    render() {
        const { isFocused } = this.props;
        const { isLoading, group } = this.state;
        if(isLoading) {
            return (
                <div style={{backgroundColor: '#fff'}}>
                    <div style={{height: window.innerHeight, backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>

                        Lade
                    </div>
                </div>
            )
        }

        const onModalBoardQr = () => {
            this.setState({openboard: false})
        }

        const openToDoDetails = (date, i) => {
            this.setState({showtodo: true, tododetail: date})
        }

        const changeStatus = (date, i) => {

                var someProperty = {...this.state.todolist}
                date.done = date.done ? false : true;
                someProperty.i = date;

               // let d = this.state.todolist.splice(i,1, date)

                this.setState({todolist: this.state.todolist})

                axios.get('https://app.kitaleicht.com/api/users/todo/done/' + date.id,{
                    headers: {
                        'Authorization': `Bearer ${this.state.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                    .then((success) => {

                    })
                    .catch((error => {
                        console.log(error.response);
                    }));


        }

        const onModalCloseProfile = () => {
            this.loadChild();
            this.setState({showprofile: false})
        }

        const onModalCloseTodolist = () => {
            this.setState({showtodo: false})
        }

        const onModalCloseDrugs = () => {
            this.loadChild();
            this.setState({showadddrugs: false})
        }

        const onModalCloseAllergie = () => {
            this.loadChild();
            this.setState({showaddallergie: false})
        }

        const onModalCloseChat = () => {
            this.setState({openchat: false})
        }

        const deleteAllergies = (id, item) => {
            axios.get('https://app.kitaleicht.com/api/users/my/child/' + this.state.child.id + '/remove/allergies/' + item.id,{
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((success) => {
                    if(id == 0){
                        this.state.child.allergies.splice(0, 1);
                    }else{
                        this.state.child.allergies.splice(id-1, 1);
                    }

                    var array = {...this.state.child};
                    this.setState({child: array});
                })
                .catch((error => {
                    console.log(error.response);
                }));
        }

        const deleteDrugs = (id,item) => {
            axios.get('https://app.kitaleicht.com/api/users/my/child/' + this.state.child.id + '/remove/drugs/' + item.id,{
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((success) => {
                    if(id == 0){
                        this.state.child.allergies.splice(0, 1);
                    }else{
                        this.state.child.drugs.splice(id-1, 1);
                    }


                    var array = {...this.state.child};
                    array.drugs = this.state.child.drugs;
                    this.setState({child: array});
                })
                .catch((error => {
                    console.log(error.response);
                }));
        }

        const editEmergencyContacts = (key, item, type) => {
            //Edit_emergency_contacts
            this.setState({edit_emergency_contacts: item})
        }

        const removeEmergencyContacts = (key,item, type) => {
            axios.get('https://app.kitaleicht.com/api/users/my/child/' + this.state.child.id + '/remove/emergency_contacts/' + item.id,{
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((success) => {
                    if(type == 'emergency_contacts') {
                        this.state.child.emergency_contacts.splice(key, 1);
                        this.setState({emergency_contacts: this.state.child.emergency_contacts});
                    }else{
                        this.state.child.contacts_persons.splice(key, 1);
                        this.setState({contacts_persons: this.state.child.contacts_persons});
                    }

                })
                .catch((error => {
                    console.log(error.response);
                }));

        }

        const onModalCloseEditEmergencyContacts = (emergency_contacts) => {
            if(emergency_contacts == null){
                this.setState({edit_emergency_contacts: null});
            }else{
                this.setState({edit_emergency_contacts: null});
            }
        }

        const onModalCloseEmergencyContacts = (emergency_contacts) => {
            if(emergency_contacts == null){
                this.setState({add_emergency_contacts: false});
            }else{
                if(emergency_contacts.type != "doctor") {
                    console.log("dri1n");
                    let emergency_contacts_old = (this.state.child.emergency_contacts == null)? [] : this.state.child.emergency_contacts;
                    emergency_contacts_old.push(emergency_contacts);

                    this.setState({add_emergency_contacts: false});
                }else{
                    console.log("drin");
                    let emergency_contacts_old = (this.state.child.contacts_persons == null)? [] : this.state.child.contacts_persons;
                    emergency_contacts_old.push(emergency_contacts);



                    this.setState({add_emergency_contacts: false});
                }

            }

        }


        const onChangeFromime = (event, date) => {
            //Moment.locale('de')

            this.setState({showCal: false, isLoading: true, dateTxt: 'Tag auswählen: '  + Moment(date, "DD.MM.YYYY HH:mm:ss").format('DD.MM.Y')})


                const onSuccess = ({data}) => {
                    this.setState({
                        isLoading: false,
                        begin_date: date,
                        todolist: data.data.todos,
                        whiteboard: data.data.whiteboard,
                        childDiary: data.data.childDiary,
                        protocol: data.data.protocol,
                        eatingplan: data.data.eatingplan,
                        childNotice: data.data.childNotice
                    });
                };

                const onFailure = error => {
                    console.log('child',error.response);
                    //this.setState({errors: error.response.data, isLoading: false});
                };

                axios.get('https://app.kitaleicht.com/api/users/diary/child/' + this.state.selectchild + '?from=' + Moment(date, "DD.MM.YYYY HH:mm:ss").format('Y-MM-DD') + '&til=' + Moment(date, "DD.MM.YYYY HH:mm:ss").format('Y-MM-DD'), {
                    headers: {
                        'Authorization': `Bearer ${this.state.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                    .then(onSuccess)
                    .catch(onFailure);



        }

        return (
            <div>
                <div style={{backgroundColor: '#fff', paddingLeft: 10}}>

                    <div style={{marginTop: 40}}>
                        <div style={{borderBottomWidth: 0}}>
                            <div style={{float: 'left'}}>
                                <h1 style={{fontWeight: '600', fontSize: 32}}>

                                    <img
                                        style={{width: 25, height: 25, marginRight: 10}}
                                        src={("/assets/icon/tagebuch_colour.png")}
                                    />

                                    Mein Kind</h1>

                            </div>
                            <div style={{float: 'right'}}>
                                {
                                    (this.state.modus == 'daily')
                                    ?
                                        null
                                        :
                                        <div activeOpacity={0.9} onClick={() => this.setState({showprofile: true})}>
                                            <p style={{fontSize: 13}}>bearbeiten</p>
                                        </div>
                                }
                            </div>
                            <div style={{clear: 'both'}}></div>
                        </div>
                    </div>


                        <Button first style={{padding: 0, textAlign: 'center',justifyContent: 'center',alignItems: 'center', width: '47%', color:(this.state.modus == 'daily') ? '#fff':'#8089C3',borderColor:(this.state.modus == 'daily') ? '#8089C3':'#eee',backgroundColor: (this.state.modus == 'daily') ? '#8089C3':'#eee'}} active={(this.state.modus == 'daily') ? true:false} onClick={() => this.setState({modus: 'daily'})}>
                            <p style={{textAlign: 'center',justifyContent: 'center',alignItems: 'center', color:(this.state.modus == 'daily') ? '#fff':'#8089C3'}}>Mein Tagebuch</p>
                        </Button>
                        <Button last style={{padding: 0, textAlign: 'center',justifyContent: 'center',alignItems: 'center', width: '47%', color:(this.state.modus == 'mychild') ? '#fff':'#8089C3',borderColor:(this.state.modus == 'mychild') ? '#8089C3':'#eee',backgroundColor: (this.state.modus == 'mychild') ? '#8089C3':'#eee'}} active={(this.state.modus == 'mychild') ? true:false}  onClick={() => this.setState({modus: 'mychild'})}>
                            <p style={{textAlign: 'center',justifyContent: 'center',alignItems: 'center', color:(this.state.modus == 'mychild') ? '#fff':'#8089C3'}}>Mein Kind</p>
                        </Button>


                    {
                        (this.state.modus == 'daily')
                    ?

                     <div>
                        <div style={{marginLeft: 10, marginRight: 10}}>
                            {
                                (!this.state.showCal) ?
                                    <Button hasText full warning rounded
                                            onClick={() => this.setState({showCal: true})} style={{
                                        borderRadius: 10,
                                        backgroundColor: '#f9f9f9',
                                        marginRight: 10,
                                        marginBottom: 10,
                                        marginTop: 10,
                                        color: '#000'
                                    }}>
                                        <p style={{color: '#000'}}>{this.state.dateTxt}</p>
                                    </Button>
                                    :
                                    <div style={{width: '100%', borderRadius: 10, backgroundColor: '#fff', marginTop: 10, marginLeft: 0}}>

                                        <div>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    variant="inline"
                                                    format="DD.MM.yyyy HH:mm"
                                                    margin="normal"
                                                    id="date-picker-inline"
                                                    label="Date picker inline"
                                                    value={this.state.begin_date}
                                                    onChange={onChangeFromime}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>

                                    </div>
                            }

                        </div>

                         <h2 style={{fontWeight: '600', margin: 15, fontSize: 22}}>
                             <img
                                 style={{width: 25, height: 25, marginRight: 10}}
                                 src={("/assets/icon/to_do_colour.png")}
                             />

                             To Do's für heute</h2>

                         {
                             this.state.todolist == null || this.state.todolist.length == 0 ?
                                    <p style={{marginLeft: 15}}>Keine To Do's heute</p>
                                 :
                                 this.state.todolist.map((item, i) =>
                                     <div activeOpacity={0.9} onClick={() => openToDoDetails(item, i)}>
                                         <Card transparent style={{marginLeft: 0, marginBottom: 10, marginRight: 10, padding: 0,borderRadius: 10}}>
                                             <CardItem style={{paddingBottom: 0, marginBottom: 0}}>
                                                 <div style={{float: 'left', width: '70%'}}>
                                                     <p style={{fontWeight: '600', fontSize: 16}}>{item.title}</p>
                                                     <p>{item.time.substr(0,item.time.length - 3)} Uhr</p>
                                                     {item.reminder != null
                                                         ?
                                                         <p style={{marginTop: 10, color: '#666'}}>Erinnerung: {
                                                             item.reminder.reminder_value < 60 ?
                                                                 item.reminder.reminder_value + ' Minuten vorher'
                                                                 :
                                                                 item.reminder.reminder_value / 60 + ' Stunden vorher'
                                                         } </p>
                                                         :
                                                         null}
                                                 </div>
                                                 <div style={{textAlign: 'right', width: '29%', float: 'right'}}>
                                                     <input type={'checkbox'} onClick={() => changeStatus(item, i)} checked={item.done} checkboxSize={40} style={{fontSize: 50, backgroundColor: (!item.done) ? '#fff': '#81CCCE', color: (!item.done) ? '#fff': '#81CCCE', borderColor: '#81CCCE', borderRadius: 32, width: 20, height: 20}}/>
                                                 </div>
                                             </CardItem>

                                         </Card>
                                     </div>
                                 )
                         }

                         <h2 style={{fontWeight: '600', margin: 15, marginBottom: 0, marginTop: 35, fontSize: 22}}>
                             <img
                                 style={{width: 25, height: 25, marginRight: 10}}
                                 src={("/assets/icon/news-colour.png")}
                             />

                         Tagesgeschehen</h2>

                         {
                             this.state.childNotice.length == 0 ? null : <h3 style={{fontWeight: '600', fontSize: 17, margin: 15, marginTop: 15, marginBottom: 15}}>Notizen aus meiner Kita</h3>
                         }

                         {
                             ((this.state.childNotice == null && this.state.childDiary.length == 0) || this.state.childNotice.length == 0 && this.state.childDiary.length == 0)
                             ?
                                 <p style={{marginLeft: 15}}>Kein Tagesgeschehen gefunden</p>
                                 :
                                 null
                         }
                         {
                             (this.state.childNotice == null)
                                 ?
                                 null
                                 :
                                 this.state.childNotice.map((item, i) =>
                                     <div style={{borderBottomWidth: (i == this.state.childNotice.length ? 0 :1), borderBottomColor: '#bbb', paddingBottom: 10, marginBottom: 10}}>
                                         <div avatar noBorder>
                                             <div>
                                                 <div style={{margin: 'auto',backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: 40, height: 40, borderRadius: 150, backgroundImage:`url(${item.user.image })` }}>

                                                 </div>
                                             </div>
                                             <div>
                                                 <p>{item.user.firstname} {item.user.lastname}</p>
                                             </div>

                                         </div>
                                         <div style={{marginLeft: 15}}>
                                             {
                                                 item.subject == null ?
                                                     null :
                                                     <p style={{marginTop: 10, color: '#000'}}>
                                                         Thema: {item.subject}
                                                     </p>
                                             }
                                             <p style={{marginTop: 10, color: '#000'}}>{item.title}</p>
                                             <p style={{marginTop: 10, color: '#666'}}>{item.text}</p>
                                         </div>
                                     </div>
                                 )
                         }


                         {
                             this.state.childDiary.length == 0 ?
                                 null
                                 :
                                 <div style={{margin: 10, marginTop: 20}}>
                                     {
                                         this.state.childDiary.map((item, i) =>
                                             (item.value == null) ? null:
                                            <div>
                                                {item.kita_diary_group_id == null ? null : <h3 style={{display: (i > 0 && this.state.childDiary[i-1].kita_diary_group_id.name == item.kita_diary_group_id.name) ? 'none' : 'flex', fontWeight: '600', fontSize: 20, margin: 15, marginLeft: 0, marginTop: 15, marginBottom: 15}}>{item.kita_diary_group_id.name}</h3>}
                                                <div style={{flexDirection: 'row', display: 'flex', justifyContent: "center",alignItems: "center", textAlign: 'center'}}>
                                                    <div style={{ flex:0.5}}><p style={{flex:1}}>{item.ref_label}</p></div>
                                                    <div style={{marginTop: 5, flex:0.4,justifyContent: "flex-end",alignItems: "flex-end"}}>
                                                        <p style={{flex:1,justifyContent: "flex-end",alignItems: "flex-end", textAlign: 'right'}}>
                                                            {
                                                                (item.ref_type == 'select')
                                                                ?
                                                                    (item.value == 0)
                                                                        ?
                                                                        <div>
                                                                            <img
                                                                                style={{width: 30, height: 30}}
                                                                                src={("../assets/icon/neu/positiv_gruen.png")}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        (item.value == 1)
                                                                            ?
                                                                            <div>
                                                                                <img
                                                                                    style={{width: 30, height: 30}}
                                                                                    src={("../assets/icon/neu/neutral_gelb.png")}
                                                                                />
                                                                            </div>
                                                                            :
                                                                        (item.value == 2)
                                                                            ?
                                                                            <div>
                                                                                <img
                                                                                    style={{width: 30, height: 30}}
                                                                                    src={("../assets/icon/neu/negativ_rot.png")}
                                                                                />
                                                                            </div>
                                                                            :
                                                                            item.value
                                                                    :
                                                                    item.value
                                                            }

                                                        </p>
                                                    </div>

                                                </div>
                                            </div>
                                         )
                                     }
                                 </div>
                         }




                         <h2 style={{fontWeight: '600', margin: 15, marginTop: 35, marginBottom: 15}}>
                             <img
                                 style={{width: 26, height: 26, marginRight: 10}}
                                 src={("../assets/icon/abholung_colour.png")}
                             />
                            Protokoll</h2>

                         {
                             (this.state.protocol == null)
                                 ?
                                 <p style={{marginLeft: 15}}>Kein Protokol gefunden</p>
                                 :
                                 this.state.protocol.map((item, i) =>

                                     <div style={{maxWidth: 300, borderBottom: 1, borderBottomWidth: 1, marginBottom: 10, borderColor: '#bbb'}}>
                                         <div style={{flexDirection: 'row', display: 'flex'}}>
                                             <p style={{flex: 0.1}}></p>
                                             <p style={{flex: 0.2}}>
                                                 {item.time}
                                             </p>
                                             <p style={{flex: 0.6}}>
                                                 {item.type.replace('pickedup','Abgeholt').replace('brought','Gebracht')}
                                             </p>
                                             <p style={{flex: 0.3, color: 'red', textAlign: 'right',justifyContent: 'flex-end',alignItems: 'flex-end'}}>
                                                 {item.coating > 0 ? item.coating + ' min' : null}
                                             </p>
                                             <p style={{flex: 0.1}}></p>
                                         </div>
                                         <div>
                                             {
                                                 <div avatar style={{ marginLeft: 20, marginRight: 10,borderBottomWidth:  0, border: 0, backgroundColor: '#fff', paddingRight: 10, marginBottom: 10, borderRadius: 10}} noBorder>
                                                     <div>
                                                         <div style={{backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: 40, height: 40, borderRadius: 150, backgroundImage:`url(${ item.collector.image })` }}>

                                                         </div>
                                                     </div>
                                                     <div>
                                                         <p>{item.collector.firstname} {item.collector.lastname}</p>
                                                     </div>
                                                 </div>
                                             }
                                         </div>
                                     </div>
                                 )
                         }


                         <h2 style={{fontWeight: '600', margin: 15, marginTop: 35, marginBottom: 15}}>
                             <img
                                 style={{width: 26, height: 26, marginRight: 10}}
                                 src={("../assets/icon/news-colour.png")}
                             />
                            Schwarzes Brett</h2>
                         {
                             (this.state.whiteboard == null)
                                 ?
                                 <p style={{marginLeft: 15}}>Keine Nachrichten gefunden</p>
                                 :
                                 <div style={{marginLeft: 20}}>
                                     {this.state.whiteboard.map((item) =>
                                         <div activeOpacity={0.9} onClick={() => this.openBoard(item)}>
                                         <Card transparent style={{marginBottom: 15, marginLeft: 0, marginRight: 15, borderRadius: 0, padding: 0}}>

                                             <CardItem style={{borderRadius: 20}}>
                                                 <div>

                                                     <h2 style={{fontWeight: '600', fontSize: 18, marginBottom: 0}}>{item.subject}</h2>
                                                     <p style={{color: '#bbb'}}>
                                                         {Moment(item.created_at).format('DD.MM.Y HH:MM')}
                                                     </p>
                                                     <p style={{marginTop: 10}}>
                                                         {item.text}
                                                     </p>
                                                 </div>
                                             </CardItem>

                                             {
                                                 (item.image.url == null) ? null
                                                     :
                                                     <CardItem cardBody style={{borderRadius: 20}}>
                                                         <div style={{backgroundImage: 'url('+item.image.url+')', backgroundSize: 'cover', maxWidth: 400, margin: 'auto', height: 200, flex: 1, borderRadius: 20}}></div>
                                                     </CardItem>
                                             }
                                         </Card>
                                         </div>
                                 )}
                                 </div>
                         }

                         <h2 style={{fontWeight: '600',  margin: 15,  marginTop: 35, marginBottom: 15}}>
                             <img
                                 style={{width: 26, height: 26, marginRight: 10}}
                                 src={("../assets/icon/essen_colour.png")}
                             />
                            Essensplan</h2>

                         {
                             (this.state.eatingplan.length == 0)
                                 ?
                                 <p style={{marginLeft: 15}}>Keine Essensplan gefunden</p>
                                 :
                                 <div>
                                     {
                                         (this.state.eatingplan.items == null)
                                             ?
                                             <p>Keine Essensplan hinterlegt</p>
                                             :
                                             this.state.eatingplan.items.map((item, i) =>

                                                 <div avatar style={{flexDirection: "row", display: 'flex',borderBottomWidth: 0, border: 0, backgroundColor: '#fff', paddingLeft: 20, paddingRight: 10, marginBottom: 10, borderRadius: 10}} noBorder>
                                                     <div style={{width: 100}}>
                                                         <span style={{color: '#8089C3', fontWeight: '600'}}>
                                                             {
                                                                 item.type == 'breakfast'
                                                                     ? 'Frühstück' :
                                                                     item.type == 'snack'
                                                                         ? 'Zwischenmahlzeit' :
                                                                         item.type == 'brunch'
                                                                             ? 'Brunch' :
                                                                             item.type == 'meal'
                                                                                 ? 'Mittagessen' :
                                                                                 item.type == 'dessert'
                                                                                     ? 'Nachtisch' :
                                                                                     item.type == 'fruit'
                                                                                         ? 'Obst' : ''
                                                             }
                                                             :</span>
                                                     </div>
                                                     <div style={{marginLeft: 20}}>
                                                         <span style={{marginTop: 5}}>{item.text}</span>
                                                     </div>
                                                     {
                                                         item.intro.length > 1 ?
                                                             <div>
                                                                 <div activeOpacity={0.9} onClick={() => alert(item.intro)}  style={{marginTop: 2}}>
                                                                     <img
                                                                         style={{width: 20, height: 20}}
                                                                         src={("../assets/icon/info-blue.png")}
                                                                     />
                                                                 </div>
                                                             </div>
                                                             :
                                                             null
                                                     }
                                                 </div>

                                             )
                                     }
                                 </div>
                         }
                     </div>
                    :
                        <div>


                            {
                                (this.state.child == null) ?
                                    null
                                    :
                                    <div>
                                        <div style={{textAlign: 'center',justifyContent: 'center',alignItems: 'center', marginTop: 15,marginBottom: 15, width: '100%'}}>
                                            <div style={{margin: 'auto',backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: 150, height: 150, borderRadius: 150, backgroundImage:`url(${this.state.child.image == null || this.state.child.image == '' ? 'https://app.kitaleicht.com/defaultimage.jpg' : this.state.child.image})` }}>

                                            </div>
                                        </div>

                                        <h1 style={{fontWeight: '600',margin: 15, marginLeft: 0}}>{this.state.child.firstname} {this.state.child.lastname}</h1>

                                        <div>
                                            <div>
                                                <div>
                                                    <div style={{width: 100, 'flex': 0.6}}><p style={{fontWeight: '600'}}>Geburtsdatum</p></div>
                                                    <div>
                                                        <p>
                                                            <p>{Moment(this.state.child.birthday).format('DD.MM.Y')}</p>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div style={{border:0, borderBottomWidth: 0}}>
                                                    <div style={{width: 100, 'flex': 0.6}}><p style={{fontWeight: '600'}}>Adresse</p></div>
                                                    <div>
                                                        <p>
                                                            {this.state.child.street},
                                                        </p>
                                                        <p>
                                                            {this.state.child.zip} {this.state.child.city}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <h2 style={{fontWeight: '600',margin: 15, marginLeft: 0, marginTop: 30, fontSize: 22}}>Meine Gruppe</h2>
                                            <div avatar style={{ marginRight: 10,borderBottomWidth:  0, border: 0, backgroundColor: '#fff', paddingRight: 10, marginBottom: 10, borderRadius: 10}} noBorder>
                                                <div>
                                                    <img
                                                        style={{width: 40, height: 40, marginLeft: 5}}
                                                        src={this.state.group.image}
                                                    />
                                                </div>
                                                <div>
                                                    <p>{this.state.group.name}</p>
                                                </div>
                                            </div>

                                            <div style={{border:0, borderBottomWidth: 0}}>
                                                <div style={{float: 'left'}}>
                                                    <h2 style={{fontWeight: '600', fontSize: 22}}>Medikamente</h2>
                                                </div>
                                                <div style={{float: 'right'}}>
                                                    <Button last style={{textAlign: 'center', justifyContent: 'center',alignItems: 'center', height: 30, width: 30, color: '#8089C3',borderColor:'#fff',backgroundColor: '#fff'}} onClick={() => this.setState({showadddrugs: true})}>
                                                        <img
                                                            style={{width: 10, height: 10}}
                                                            src={"../assets/icon/add.png"}
                                                        />
                                                    </Button>
                                                </div>
                                                <div style={{clear: 'both'}}></div>
                                            </div>

                                            {
                                                (this.state.child.drugs == null || this.state.child.drugs.length == 0)
                                                    ?
                                                    <p style={{marginLeft: 15}}>Keine Medikamente hinterlegt</p>
                                                    :
                                                    this.state.child.drugs.map((item, i) =>

                                                            <div avatar style={{ marginRight: 10, marginLeft: 0, borderBottomWidth: (i == this.state.child.drugs.length -1 ) ? 0: 0.5, border: 0, backgroundColor: '#fff', paddingRight: 10, marginBottom: 10, borderRadius: 10}} noBorder>
                                                                <div>
                                                                    <p style={{fontWeight: '600'}}>{item.name}</p>
                                                                </div>
                                                                <div>
                                                                    <p>{item.frequency == null ? '' : item.frequency}</p>
                                                                </div>
                                                            </div>

                                                    )
                                            }

                                            <div style={{border:0, borderBottomWidth: 0}}>
                                                <div style={{float: 'left'}}>
                                                    <h2 style={{fontWeight: '600', fontSize: 22}}>Allergien</h2>
                                                </div>
                                                <div style={{float: 'right'}}>
                                                    <Button last style={{textAlign: 'center', justifyContent: 'center',alignItems: 'center', height: 30, width: 30, color: '#8089C3',borderColor:'#fff',backgroundColor: '#fff'}} onClick={() => this.setState({showaddallergie: true})}>
                                                        <img
                                                            style={{width: 10, height: 10}}
                                                            src={"../assets/icon/add.png"}
                                                        />
                                                    </Button>
                                                </div>
                                                <div style={{clear: 'both'}}></div>
                                            </div>

                                            {
                                                (this.state.child.allergies == null || this.state.child.allergies.length == 0)
                                                    ?
                                                    <p style={{marginLeft: 15}}>Keine Allergien hinterlegt</p>
                                                    :
                                                    this.state.child.allergies.map((item, i) =>

                                                        <div avatar style={{borderBottomWidth: (i == (this.state.child.allergies.length - 1)) ? 0:  0.5, marginLeft: 0, backgroundColor: '#fff', marginRight: 10, paddingRight: 10, marginBottom: 10, borderRadius: 10}} noBorder>
                                                            <div>
                                                                <p style={{fontWeight: '600'}}>{item.name}</p>
                                                            </div>
                                                            <div>
                                                                <p>{item.frequency == null ? '' : item.frequency}</p>
                                                            </div>
                                                        </div>

                                                    )
                                            }

                                            <div style={{border:0, borderBottomWidth: 0}}>
                                                <div style={{float: 'left'}}>
                                                    <h2 style={{fontWeight: '600', fontSize: 22}}>Ärzte</h2>
                                                </div>
                                                <div style={{float: 'right'}}>
                                                    <Button last style={{textAlign: 'center', justifyContent: 'center',alignItems: 'center', height: 30, width: 30, color: '#8089C3',borderColor:'#fff',backgroundColor: '#fff'}} onClick={() => this.setState({emergency_type: 'doctor', add_emergency_contacts: true})}>
                                                        <img
                                                            style={{width: 10, height: 10}}
                                                            src={"../assets/icon/add.png"}
                                                        />
                                                    </Button>
                                                </div>
                                                <div style={{clear: 'both'}}></div>
                                            </div>

                                            {
                                                 (this.state.child.contacts_persons == null || this.state.child.contacts_persons.length == 0)
                                                    ?
                                                    <p style={{marginLeft: 15}}>Keine Ärzte gefunden</p>
                                                    :
                                                    this.state.child.contacts_persons.map((item, i) =>
                                                        <div onClick={() => editEmergencyContacts(i, item, 'contacts_persons')} avatar style={{ flexDirection: "row", display: 'flex', marginRight: 10,borderBottomWidth:  (i == (this.state.child.contacts_persons.length - 1)) ? 0:  0.5, border: 0, backgroundColor: '#fff', paddingRight: 10, marginBottom: 10, borderRadius: 10}} noBorder>
                                                            <div>
                                                                <img
                                                                    style={{width: 45, height: 45, marginTop: -5}}
                                                                    src={"../assets/icon/doctor.png"}
                                                                />
                                                            </div>
                                                            <div style={{flexDirection: "row", display: 'flex', marginLeft: 10, marginTop: -10}}>
                                                                <p>{item.firstname} {item.lastname}</p>
                                                                <p style={{ marginLeft: 7}}>{typeof item.type == "undefined"? item.type:item.type.replace('contact','Notfallkontakt').replace('doctor','Arzt')}</p>
                                                            </div>
                                                        </div>
                                                    )
                                            }

                                            <div style={{border:0, borderBottomWidth: 0}}>
                                                <div style={{float: 'left'}}>
                                                    <h2 style={{fontWeight: '600', fontSize: 22}}>Notfallkontakt</h2>
                                                </div>
                                                <div style={{float: 'right'}}>
                                                    <Button last style={{textAlign: 'center', justifyContent: 'center',alignItems: 'center', height: 30, width: 30, color: '#8089C3',borderColor:'#fff',backgroundColor: '#fff'}} onClick={() => this.setState({emergency_type: 'contact', add_emergency_contacts: true})}>
                                                        <img
                                                            style={{width: 10, height: 10}}
                                                            src={"../assets/icon/add.png"}
                                                        />
                                                    </Button>
                                                </div>
                                                <div style={{clear: 'both'}}></div>
                                            </div>

                                            {
                                                (this.state.child.emergency_contacts == null || this.state.child.emergency_contacts.length == 0)
                                                    ?
                                                    <p style={{marginLeft: 15}}>Notfallkontakt</p>
                                                    :
                                                    this.state.child.emergency_contacts.map((item, i) =>
                                                        <div onClick={() => editEmergencyContacts(i, item, 'emergency_contacts')} avatar style={{ flexDirection: "row", display: 'flex', marginRight: 10,borderBottomWidth:  (i == (this.state.child.emergency_contacts.length - 1)) ? 0:  0.5, border: 0, backgroundColor: '#fff', paddingRight: 10, marginBottom: 10, borderRadius: 10}} noBorder>
                                                            <div>
                                                                <img
                                                                    style={{width: 35, height: 35, marginTop: -5}}
                                                                    src={"../assets/icon/contacts.png"}
                                                                />
                                                            </div>
                                                            <div style={{flexDirection: "row", display: 'flex', marginLeft: 10, marginTop: -10}}>
                                                                <p>{item.firstname} {item.lastname}</p>
                                                                <p style={{ marginLeft: 7}}>{typeof item.type == "undefined"? item.type:item.type.replace('contact','Notfallkontakt').replace('doctor','Arzt')}</p>
                                                            </div>
                                                        </div>

                                                    )
                                            }

                                            <div style={{border:0, borderBottomWidth: 0}}>
                                                <div>
                                                    <h2 style={{fontWeight: '600', fontSize: 22}}>Bringer / Abholer</h2>
                                                </div>
                                            </div>

                                            {
                                                (this.state.child.collector == null || this.state.child.collector.length == 0)
                                                    ?
                                                    <p style={{marginLeft: 15}}>Keine Bringer / Abholer vorhanden</p>
                                                    :
                                                    this.state.child.collector.map((item, i) =>
                                                    (item.unique_collector == 1) ? null :
                                                        <div avatar style={{ flexDirection: "row", display: 'flex', marginRight: 10,borderBottomWidth:  (i == (this.state.child.collector.length - 1)) ? 0:  0.5, border: 0, backgroundColor: '#fff', paddingRight: 10, marginBottom: 10, borderRadius: 10}} noBorder>
                                                            <div>
                                                                <div style={{margin: 'auto',backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: 40, height: 40, borderRadius: 150, backgroundImage:`url(${(item.image == null) ? 'https://app.kitaleicht.com/defaultimage.jpg': item.image })` }}>

                                                                </div>
                                                            </div>
                                                            <div style={{marginLeft: 10, marginTop: -5}}>
                                                                <p>{item.profile.firstname} {item.profile.lastname}</p>
                                                            </div>

                                                        </div>

                                                    )
                                            }
                                        </div>
                                    </div>

                            }
                        </div>
                    }


                </div>

                {(this.state.openboard) ? <Board item={this.state.boarditem} onModalBoardQr={onModalBoardQr} modal={this.state.openboard}/> : null}
                {(this.state.showprofile) ? <ChildProfile profile={this.state.child} onModalCloseProfile={onModalCloseProfile} modal={this.state.showprofile}/> : null}
                {(this.state.showaddallergie) ? <AddAllergies onModalCloseAllergie={onModalCloseAllergie} modal={this.state.showaddallergie}/> : null}
                {(this.state.showadddrugs) ? <AddDrugs onModalCloseDrugs={onModalCloseDrugs} modal={this.state.showadddrugs}/> : null}
                {(this.state.showtodo) ? <Showtododetails todo={this.state.tododetail} onModalCloseTodolist={onModalCloseTodolist} modal={this.state.showtodo}/> : null}

                {(this.state.add_emergency_contacts) ? <Emergency_contacts type={this.state.emergency_type} token={this.state.token} child={this.state.child} onModalCloseEmergencyContacts={onModalCloseEmergencyContacts} modal={this.state.add_emergency_contacts}/> : null}
                {(this.state.edit_emergency_contacts != null) ? <Edit_emergency_contacts item={this.state.edit_emergency_contacts} type={this.state.emergency_type} token={this.state.token} child={this.state.child} onModalCloseEditEmergencyContacts={onModalCloseEditEmergencyContacts} modal={this.state.edit_emergency_contacts}/> : null}
            </div>
        );
    }
};

export default MyChild;


/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {Component, Fragment} from 'react';
import axios from 'axios';
import ListItem from '@material-ui/core/ListItem';

class MyCareHours extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={isLoading: true, customer: localStorage.getItem('customer'), token: localStorage.getItem('access_token'), data: []};

    }

    componentDidMount(){
        this.loadDiary();
    }

    loadDiary() {

        const onSuccess = ({data}) => {
            this.setState({
                isLoading: false,
                data: data.caretime
            });
        };

        const onFailure = error => {
            console.log(error.response);
            //this.setState({errors: error.response.data, isLoading: false});
        };

        axios.get('https://app.kitaleicht.com/api/users/my/child/' + this.state.customer + '/caretime', {
            headers: {
                'Authorization': `Bearer ${this.state.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(onSuccess)
            .catch(onFailure);


    }

    render() {
        const {isLoading, data} = this.state;

        if(isLoading) {
            return (
                <Fragment style={{backgroundColor: '#fff'}}>
                    Lade
                </Fragment>
            )
        }

        return (
            <Fragment>
                <div style={{backgroundColor: '#fff', margin: 10}}>

                    <div style={{marginTop: 0}}>
                        <ListItem style={{borderBottomWidth: 0}}>
                            <div>
                                <h1 style={{fontWeight: '600', fontSize: 32}}>{/*<SvgXml width="30" height="30" xml={dairyIcon} style={{fill:'#000'}} />*/}
                                    <img
                                        style={{width: 30, height: 30}}
                                        src={("../assets/icon/abholung_colour.png")}
                                    />
                                    &nbsp; Betreuungszeiten</h1>
                            </div>

                        </ListItem>
                    </div>



                    <div>

                        {
                            (this.state.data.length == 0)
                                ?
                                <p style={{marginLeft: 15}}>Keine Zeiten gefunden</p>
                                :
                                <div>
                                    {
                                        this.state.data.map((item, i) =>

                                            <ListItem avatar style={{borderBottomWidth: 0, border: 0, backgroundColor: '#fff', paddingRight: 10, marginBottom: 10, borderRadius: 10}} noBorder>
                                                <div style={{width: 100}}>
                                                    <span style={{color: '#8089C3', fontWeight: '600', textAlign: 'left'}}>{item.day}:</span>
                                                </div>
                                                <div style={{flexDirection: 'column'}}>

                                                </div>
                                                <div>
                                                    <span style={{marginTop: 5}}>{item.from.substr(0, item.from.length - 3)} bis {item.to.substr(0, item.from.length - 3)} Uhr</span>
                                                </div>
                                            </ListItem>

                                        )
                                    }
                                </div>
                        }

                    </div>



                </div>

            </Fragment>
        );
    }
};

export default MyCareHours;

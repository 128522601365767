/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {Component, Fragment} from 'react';
import { Button } from '@material-ui/core';
import axios from 'axios'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from "@material-ui/core/TextField";


class Profile extends React.Component<Props> {

    constructor(props){
        super(props);
        this.state ={errors: [],modus: 'list', isLoading: true, token: localStorage.getItem('access_token'), data: []};

        this.inputFileRef = React.createRef();
        this.onFileChange = this.handleFileChange.bind( this );
        this.onBtnClick = this.handleBtnClick.bind( this );
    }

    componentDidMount(){

            const onSuccess = ({data}) => {
                console.log(data.data);
                this.setState({isLoading: false, profile: data.data, firstname: data.data.profile.firstname, lastname: data.data.profile.lastname, image: data.data.profile.image, phone: data.data.profile.phone, email: data.data.email, street: data.data.profile.street, zip: data.data.profile.zip, city: data.data.profile.city});
            };

            const onFailure = error => {
                //console.log(error.response);
                //this.setState({errors: error.response.data, isLoading: false});
            };



            axios.get('https://app.kitaleicht.com/api/users/profile', {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`
                }
            })
                .then(onSuccess)
                .catch(onFailure);

    }

    changePassword() {
        this.setState({saving: true});
        axios.post('https://app.kitaleicht.com/api/users/profile/change/password',
            {
                'old_password': this.state.old_password,
                'new_password': this.state.password,
                'new_password_confirmation': this.state.password_repeat
            }, {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`
                }
            })
            .then((success) => {

                this.setState({saving: false});
                alert('erfolgreich!');
            })
            .catch((error => {
                console.log('error',error.response);
                this.setState({saving: false});
                if(error.response.status===500){
                    alert("Fehler 500 - ein unbekanntes Problem. Wende  dich an den technischen Support.");
                }else{
                    let errors = []

                    Object.keys(error.response.data.message).map(function(key, val) {

                        errors.push(error.response.data.message[key][0]);
                    });
                    this.setState({errors: errors});
                }
            }));
    }

    addToDo() {
        this.setState({saving: true});
        axios.post('https://app.kitaleicht.com/api/users/profile',
            {
                'firstname': this.state.firstname,
                'lastname': this.state.lastname,
                'street': this.state.street,
                'zip': this.state.zip,
                'city': this.state.city,
                'phone': this.state.phone,
                'email': this.state.email,
                'image': this.state.image
            }, {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`
                }
            })
            .then((success) => {

                localStorage.setItem("profilimage", success.data.data.image);
                this.setState({saving: false});

                alert('erfolgreich!')
            })
            .catch((error => {
                console.log('error',error);
                this.setState({saving: false});
                if(error.response.status===500){
                    alert("Fehler 500 - ein unbekanntes Problem. Wende  dich an den technischen Support.");
                }else{
                    let errors = []

                    Object.keys(error.response.data.message).map(function(key, val) {

                        errors.push(error.response.data.message[key][0]);
                    });
                    this.setState({errors: errors});
                }
            }));

    }

    handleFileChange(event){
        let image = '';
        this.getBase64(event.target.files[0], (result) => {
            this.setState({image: result});
        });

    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    openDialog = () => {
        /*
        let me = this;
        ActionSheet.show({options: ["Kamera", "Foto- und Videomediathek", "Abbrechen"],
                cancelButtonIndex: 4,
                title: ""
            },
            buttonIndex => {
                if(buttonIndex == 0) {
                    ImagePicker.openCamera({
                        multiple: false,
                        includeBase64: true
                    }).then(images => {
                        me.setState({image: images.data});
                    });
                }else if(buttonIndex == 1) {

                    ImagePicker.openPicker({
                        multiple: false,
                        includeBase64: true
                    }).then(images => {
                        me.setState({image: images.data});
                    });
                }
            });*/
    }

    handleBtnClick() {
        /*Collecting node-element and performing click*/
        this.inputFileRef.current.click();
    }

    render() {
        const {errors} = this.state;
        const deviceWidth = window.innerWidth / 2;
        const deviceHeight = window.innerHeight / 2;

        return (
            <div>
                <div style={{marginTop: 40}}>
                    <div style={{borderBottomWidth: 0}}>
                        <div>
                            <h1 style={{fontWeight: '600', fontSize: 32}}>
                                <img
                                    style={{width: 25, height: 25, marginRight: 10}}
                                    src={("/assets/icon/plaene_colour.png")}
                                />Profil</h1>
                        </div>
                    </div>
                </div>
                <div>
                    {
                    (this.state.profile == null)
                        ?
                        null
                        :
                        (this.state.modus == "password")
                            ?
                            <div style={{marginLeft: 10}}>
                                <h3 style={{marginTop: 20, marginLeft: 15}}>Passwort ändern</h3>

                                <div style={{borderRadius: 20, marginTop: 10}}>
                                    <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                        <p style={{marginLeft: 10}}>Aktuelles Passwort</p>
                                        <TextField autoCompleteType={'password'} name={'old_password'} secureTextEntry={true} value={this.state.old_password} placeholder={'Pflichtfeld'} style={{borderRadius: 10, width: "100%",  backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                               onChange={e => this.setState({ old_password: e.target.value })}
                                        />
                                    </div>
                                    <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                        <p style={{marginLeft: 10}}>Neues Passwort</p>
                                        <TextField autoCompleteType={'password'} name={'password'} secureTextEntry={true} value={this.state.password} placeholder={'Pflichtfeld'} style={{borderRadius: 10, width: "100%",  backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                   onChange={e => this.setState({ password: e.target.value })}
                                        />
                                    </div>
                                    <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                        <p style={{marginLeft: 10}}>Neues Passwort wiederholen</p>
                                        <TextField autoCompleteType={'password'} name={'password_repeat'} secureTextEntry={true} value={this.state.password_repeat} placeholder={'Pflichtfeld'} style={{borderRadius: 10, width: "100%",  backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                            onChange={e => this.setState({ password_repeat: e.target.value })}
                                        />
                                    </div>
                                    {
                                        this.state.saving ?
                                            <span>Lade</span>
                                            :
                                            <Button hasText full warning rounded onClick={() => this.changePassword()} style={{marginTop: 10, marginLeft: 10, marginRight: 10, backgroundColor: '#8089C3'}}>
                                                <span>Speichern</span>
                                            </Button>
                                    }

                                    {
                                        errors.length == 0 ? null
                                            :
                                            <div stlye={{
                                                width: '100%',
                                                marginLeft: 0,
                                                marginRight: 0,
                                                backgroundColor: '#B22222',
                                                padding: 10
                                            }}>
                                                {
                                                    errors.map((tile, key) => (
                                                        <div stackedLabel style={{borderBottomWidth: 0, marginTop: 5,padding: 5, paddingTop:0}}>
                                                            <span style={{color: '#B22222'}}>{tile}</span>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    }
                                </div>

                            </div>
                            :
                            (this.state.modus == "edit")
                                ?
                                <div style={{marginLeft: 10}}>
                                    <h3 style={{marginTop: 20, marginLeft: 15}}>Mein Konto</h3>

                                    <div style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>

                                        <div>
                                            <div style={{margin: 'auto',backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: 150, height: 150, borderRadius: 150, backgroundImage:`url(${(this.state.image == null) ? (this.state.profile.image == null) ? 'https://app.kitaleicht.com/defaultimage.jpg' : this.state.profile.image : this.state.image})` }}>

                                            </div>
                                        </div>
                                        <span style={{display: 'none'}}><input type="file" name="file" ref={this.inputFileRef} onChange={this.onFileChange}/></span>
                                        <Button hasText transparent style={{marginBottom: 0, marginLeft: 10, marginRight: 10, justifyContent: "flex-start"}} full onClick={this.onBtnClick}>
                                            +
                                            <span style={{color: '#81CCCE', fontSize: 18}}>Bild bearbeiten</span>
                                        </Button>
                                    </div>


                                    <div style={{borderRadius: 20, marginTop: 10}}>
                                        <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                            <p style={{marginLeft: 10}}>Vorname</p>
                                            <TextField name={'firstname'} value={this.state.firstname} placeholder={'Pflichtfeld'} style={{borderRadius: 10, width: "100%",  backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                   onChange={e => this.setState({ firstname: e.target.value })}
                                            />
                                        </div>
                                        <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                            <p style={{marginLeft: 10}}>Nachname</p>
                                            <TextField name={'lastname'} value={this.state.lastname} placeholder={'Pflichtfeld'} style={{borderRadius: 10, width: "100%",  backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                   onChange={e => this.setState({ lastname: e.target.value })}/>
                                        </div>
                                        <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                            <p style={{marginLeft: 10}}>Straße</p>
                                            <TextField name={'street'} value={this.state.street} placeholder={'Pflichtfeld'} style={{borderRadius: 10, width: "100%",  backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                       onChange={e => this.setState({ street: e.target.value })} />
                                        </div>
                                        <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                            <p style={{marginLeft: 10}}>PLZ</p>
                                            <TextField name={'zip'} value={this.state.zip} placeholder={'Pflichtfeld'} style={{borderRadius: 10, width: "100%",  backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                       onChange={e => this.setState({ zip: e.target.value })} />
                                        </div>
                                        <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                            <p style={{marginLeft: 10}}>Ort</p>
                                            <TextField name={'city'} value={this.state.city} placeholder={'Pflichtfeld'} style={{borderRadius: 10, width: "100%",  backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                onChange={e => this.setState({ city: e.target.value })} />
                                        </div>
                                        <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                            <p style={{marginLeft: 10}}>E-Mail</p>
                                            <TextField name={'email'} value={this.state.email} placeholder={'Pflichtfeld'} style={{borderRadius: 10, width: "100%",  backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                onChange={e => this.setState({ email: e.target.value })} />
                                        </div>
                                        <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                            <p style={{marginLeft: 10}}>Telefon</p>
                                            <TextField name={'phone'} value={this.state.phone} placeholder={'Pflichtfeld'} style={{borderRadius: 10, width: "100%",  backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                onChange={e => this.setState({ phone: e.target.value })} />
                                        </div>
                                    </div>
                                    {
                                        this.state.saving ?
                                            <span>Laden</span>
                                            :
                                            <Button hasText full warning rounded onClick={() => this.addToDo()} style={{width: 150, marginTop: 10,marginRight: 15, backgroundColor: '#8089C3'}}>
                                                <span style={{color: '#fff'}}>Speichern</span>
                                            </Button>
                                    }

                                    {
                                        errors.length == 0 ? null
                                            :
                                            <div stlye={{
                                                width: '100%',
                                                marginLeft: 0,
                                                marginRight: 0,
                                                backgroundColor: '#B22222',
                                                padding: 10
                                            }}>
                                                {
                                                    errors.map((tile, key) => (
                                                        <div stackedLabel style={{borderBottomWidth: 0, marginTop: 5,padding: 5, paddingTop:0}}>
                                                            <span style={{color: '#B22222'}}>{tile}</span>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    }


                                </div>
                                :
                                <div>
                                    <div style={{justifyContent: 'center', textAlign: 'center'}}>
                                        <div style={{margin: 'auto',backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: 150, height: 150, borderRadius: 150, backgroundImage:`url(${(this.state.profile.image == null) ? 'https://app.kitaleicht.com/defaultimage.jpg' : this.state.profile.image})` }}>

                                        </div>

                                        <h1 style={{padding:20, margin: 0, paddingTop: 10}}>{this.state.profile.profile.firstname} {this.state.profile.profile.lastname}</h1>

                                        <div style={{marginTop: 0, flexDirection: 'row'}}>

                                            <Button hasText full warning rounded onClick={() => this.setState({modus: 'edit'})} style={{backgroundColor: '#8089C3'}}>
                                                <span style={{color: '#fff'}}>bearbeiten</span>
                                            </Button>
                                            <Button hasText full warning rounded onClick={() => this.setState({modus: 'password'})} style={{backgroundColor: '#81CCCE', marginLeft: 20}}>
                                                <span style={{color: '#fff'}}>Passwort ändern</span>
                                            </Button>
                                        </div>
                                    </div>

                                    <h3 style={{marginTop: 20, marginLeft: 15}}>Kontaktdaten</h3>
                                    <div style={{borderRadius: 20, marginTop: 10}}>
                                        <ListItem>
                                            <ListItemText style={{flex: 0.3}}>
                                                <span>E-Mail</span>
                                            </ListItemText>
                                            <ListItemText style={{textAlign: 'right', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                                                <span>{this.state.profile.email}</span>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText style={{flex: 0.3}}>
                                                <span>Straße</span>
                                            </ListItemText>
                                            <ListItemText style={{textAlign: 'right', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                                                <span>{this.state.profile.profile.street}</span>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText style={{flex: 0.3}}>
                                                <span>PLZ / Ort</span>
                                            </ListItemText>
                                            <ListItemText style={{textAlign: 'right', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                                                <span>{this.state.profile.profile.zip} {this.state.profile.profile.city}</span>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText style={{flex: 0.3}}>
                                                <span>Telefon</span>
                                            </ListItemText>
                                            <ListItemText style={{textAlign: 'right', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                                                <span>{this.state.profile.profile.phone}</span>
                                            </ListItemText>
                                        </ListItem>
                                    </div>

                                </div>
                    }
                </div>
            </div>
        );
    }
};

export default Profile;

/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {Component, Fragment} from 'react';
import axios from 'axios';
import ListItem from "@material-ui/core/ListItem";

class MyKita extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={childNotice: [], eatingplan: [], childDiary: [], begin_date: new Date(), end_date: new Date(), modus: 'daily', isLoading: true, child: null, todolist: [], child: localStorage.getItem('child'),token: localStorage.getItem('access_token')};
    }


    componentDidMount(){
        this.loadDiary();
    }

    loadDiary() {

        const onSuccess = ({data}) => {
            this.setState({
                isLoading: false,
                eatingplan: data.data.eatingplan,
                dailyplan: data.data.dailyplan,
                group: data.data.group,
                kita: data.data.kita,
            });
        };

        const onFailure = error => {

        };

        axios.get('https://app.kitaleicht.com/api/users/mykita/' + this.state.child, {
            headers: {
                'Authorization': `Bearer ${this.state.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(onSuccess)
            .catch(onFailure);

    }

    render() {
        const {isLoading} = this.state;

        if(isLoading) {
            return (
                <Fragment style={{backgroundColor: '#fff'}}>
                    Laden
                </Fragment>
            )
        }

        return (
            <Fragment>
                <div style={{backgroundColor: '#fff', paddingLeft: 10}}>

                    <div style={{marginTop: 10}}>
                        <ListItem style={{borderBottomWidth: 0}}>
                            <div>
                                <h1 style={{fontWeight: '600', fontSize: 32}}>{/*<SvgXml width="30" height="30" xml={dairyIcon} style={{fill:'#000'}} />*/}
                                    <img
                                        style={{width: 30, height: 30}}
                                        src={("../assets/icon/beirat_colour.png")}
                                    />
                                    &nbsp; Meine Kita</h1>
                            </div>
                        </ListItem>
                    </div>

                    <div>


                        {
                            (this.state.kita == null) ?
                                null
                                :
                                <div>
                                    <h1 style={{fontWeight: '600',margin: 15}}>{this.state.kita.name}</h1>

                                    <div>
                                        <div>
                                            <ListItem style={{border:0, borderBottomWidth: 0, flexDirection: 'row', display: 'flex'}}>
                                                <div style={{width: 60, flex: 0.1}}><span style={{fontWeight: '600'}}>Adresse</span></div>
                                                <div>
                                                    <span style={{display: 'block', marginLeft: 15}}>
                                                        {this.state.kita.address[0].street},

                                                        {this.state.kita.address[0].zip} {this.state.kita.address[0].city}
                                                    </span>
                                                </div>
                                            </ListItem>
                                            <ListItem style={{border:0, borderBottomWidth: 0, flexDirection: 'row', display: 'flex'}}>
                                                <div style={{width: 60, flex: 0.1}}><span style={{fontWeight: '600'}}>Telefon</span></div>
                                                <div>
                                                    <a href={'tel://' + this.state.kita.address[0].phone}>
                                                        <span>{this.state.kita.address[0].phone}</span>
                                                    </a>
                                                </div>
                                            </ListItem>
                                            <ListItem style={{border:0, borderBottomWidth: 0, flexDirection: 'row', display: 'flex'}}>
                                                <div style={{width: 60, flex: 0.1}}><span style={{fontWeight: '600'}}>E-Mail</span></div>
                                                <div style={{marginLeft: 5}}>
                                                    <a href={'mailto://' + this.state.kita.address[0].email}>
                                                        <span>{this.state.kita.address[0].email}</span>
                                                    </a>
                                                </div>
                                            </ListItem>
                                        </div>

                                        <h2 style={{fontWeight: '600',margin: 15, fontSize: 22}}><img
                                            style={{width: 26, height: 26}}
                                            src={("../assets/icon/gruppe_colour.png")}
                                        /> Meine Gruppe</h2>
                                        <ListItem avatar style={{ marginRight: 10,borderBottomWidth:  0, border: 0, backgroundColor: '#fff', paddingRight: 10, marginBottom: 10, borderRadius: 10}} noBorder>
                                            <div>
                                                <div style={{margin: 'auto',backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: 40, height: 40, borderRadius: 150, backgroundImage:`url(${this.state.group.image})` }}>

                                                </div>
                                            </div>
                                            <div style={{marginLeft: 10}}>
                                                <span>{this.state.group.name}</span>
                                            </div>
                                        </ListItem>
                                    </div>

                                    <h2 style={{fontWeight: '600',  margin: 15,  marginTop: 15, marginBottom: 15}}><img
                                        style={{width: 26, height: 26}}
                                        src={("../assets/icon/tagebuch_colour.png")}
                                    /> Tagesplan</h2>

                                    {
                                        (this.state.dailyplan == null || this.state.dailyplan.length == 0)
                                            ?
                                            <p style={{marginLeft: 15}}>Keine Tagesplan gefunden</p>
                                            :
                                            <div>
                                                {
                                                    (this.state.eatingplan.items == null)
                                                        ?
                                                        <p style={{marginLeft: 15}}>Keinen Tagesplan gefunden</p>
                                                        :
                                                        this.state.dailyplan.items.map((item, i) =>

                                                            <ListItem avatar style={{borderBottomWidth: 0, border: 0, backgroundColor: '#fff', paddingRight: 10, marginBottom: 10, borderRadius: 10}} noBorder>
                                                                <div style={{width: 100, flexDirection: 'column'}}>
                                                                    <span style={{display: 'block',color: '#8089C3', fontWeight: '600'}}>
                                                                        {
                                                                            item.day == 1
                                                                                ? 'Montag' :
                                                                                item.day == 2
                                                                                    ? 'Dienstag' :
                                                                                    item.day == 3
                                                                                        ? 'Mittwoch' :
                                                                                        item.day == 4
                                                                                            ? 'Donnerstag' :
                                                                                            item.day == 5
                                                                                                ? 'Freitag' :
                                                                                                item.day == 6
                                                                                                    ? 'Samstag' : 'Sonntag'
                                                                        }
                                                                         :</span>
                                                                    <span style={{display: 'block', color: '#888', fontSize: 14}}>({(item.time == null) ? '-' : item.time.substr(0,5)} Uhr)</span>
                                                                </div>
                                                                <div style={{flexDirection: 'column'}}>
                                                                    <span style={{display: 'block'}}>{item.subject}</span>
                                                                    <span style={{display: 'block'}}>{item.description}</span>
                                                                </div>
                                                            </ListItem>

                                                        )
                                                }
                                            </div>
                                    }

                                    <h2 style={{fontWeight: '600',  margin: 15,  marginTop: 15, marginBottom: 15}}><src
                                        style={{width: 26, height: 26}}
                                        src={("../assets/icon/essen_colour.png")}
                                    /> Essensplan</h2>

                                    {
                                        (this.state.eatingplan.length == 0)
                                            ?
                                            <p style={{marginLeft: 15}}>Keine Essensplan gefunden</p>
                                            :
                                            <div>
                                                {
                                                    (this.state.eatingplan.items == null)
                                                        ?
                                                        <p>Keine Essensplan hinterlegt</p>
                                                        :
                                                        this.state.eatingplan.items.map((item, i) =>

                                                            <ListItem avatar style={{borderBottomWidth: 0, border: 0, backgroundColor: '#fff', paddingRight: 10, marginBottom: 10, borderRadius: 10}} noBorder>
                                                                <div style={{width: 100}}>
                                                                    <span style={{color: '#8089C3', fontWeight: '600'}}>
                                                                        {
                                                                            item.type == 'breakfast'
                                                                            ? 'Frühstück' :
                                                                                item.type == 'snack'
                                                                                    ? 'Zwischenmahlzeit' :
                                                                                    item.type == 'brunch'
                                                                                        ? 'Brunch' :
                                                                                        item.type == 'meal'
                                                                                            ? 'Mittagessen' :
                                                                                            item.type == 'dessert'
                                                                                                ? 'Nachtisch' :
                                                                                                item.type == 'fruit'
                                                                                                    ? 'Obst' : ''
                                                                        }
                                                                        :</span>
                                                                </div>
                                                                <div>
                                                                    <span style={{marginTop: 5}}>{item.text}</span>
                                                                </div>
                                                                {
                                                                    item.intro.length > 1 ?
                                                                        <div>
                                                                            <a href={"#"} onClick={alert(item.intro)}  style={{marginTop: 2}}>

                                                                                <img
                                                                                    style={{width: 20, height: 20}}
                                                                                    src={("../assets/icon/info-blue.png")}
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </ListItem>

                                                        )
                                                }
                                            </div>
                                    }
                                </div>

                        }
                    </div>



                </div>

            </Fragment>
        );
    }
};

export default MyKita;

/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardItem from '@material-ui/core/CardContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Todolist from "../components/modal/todolist";
import Absence from "../components/modal/absence";
import Collector from "../components/modal/collector";
import Contact from "../components/modal/contact";
import Diseases from "../components/modal/diseases";
import Board from "../components/modal/Board";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class Dashboard extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={group: JSON.parse(localStorage.getItem('group')).id, child: localStorage.getItem('child'), token: localStorage.getItem('access_token'), childimage: localStorage.getItem('childimage'), activeWhiteboardPage: 0, todo_id: null, show_switch_child: localStorage.getItem('count') > 0 ? true : false, openboard: false, boarditem: [], showprofile: false, image: "",isLoading: true, media: null, whiteboardManagement: [], whiteboard: [], openmodal: {tododiv: false, cotactrequest: false, diseases: false, collector: false, delay: false, absence: false, qr: false, changechild: false, Approval: false}};
    }
/*
    componentDidUpdate(prevProps) {
        console.log('meindata3', prevProps.route);

        if(!prevProps.isFocused && this.props.isFocused){

            this.load('profilimage').then((token) => {
                if(token != '' && token != null) {
                    this.setState({image: token})
                }
            });


                const onSuccess = ({data}) => {
                    this.setState({isLoading: false, calendar: data.data.calendar, media: data.data.media, whiteboard: data.data.whiteboard, whiteboardManagement: data.data.whiteboard_public,openmodal: {}});
                };
                const onFailure = error => {
                    console.log(error.response.status);
                    if(error.response.status == 401){
                        this.logout();
                    }
                    //this.setState({errors: error.response.data, isLoading: false});
                };

                axios.get('https://app.kitaleicht.com/api/users/dashboard/customer/' + localStorage.getItem('customer')+'?child='+this.state.child + '&group='+this.state.group, {
                    headers: {
                        'Authorization': `Bearer ${this.state.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                    .then(onSuccess)
                    .catch(onFailure);

        }
    }
*/
    componentDidMount(){

        let backup = this;

            const onSuccess = ({data}) => {
                console.log(data.data.calendar);
                this.setState({isLoading: false, calendar: data.data.calendar, media: data.data.media,whiteboardManagement: data.data.whiteboard_public, whiteboard: data.data.whiteboard, openmodal: {}});
            };
            const onFailure = error => {
                console.log(error);


                //this.setState({errors: error.response.data, isLoading: false});
            };


            axios.get('https://app.kitaleicht.com/api/users/dashboard/customer/' + localStorage.getItem('customer')+'?child='+this.state.child + '&group='+this.state.group, {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(onSuccess)
                .catch(onFailure);

    }

    openDialog(slug) {
        if(slug == 'changechild') {
            window.location = '/kinder'
           // this.props.navigation.navigate('ChildList', 'return');
            /*this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'Childdiv', {back} }],
            });*/
        }else{

            var someProperty = {...this.state.openmodal}
            someProperty[slug] = someProperty[slug] ? false : true;
            this.setState({openmodal: someProperty})

        }
    }

    logout = async () => {

    };

    renderIcons() {
        const {childimage} = this.state;
        const JSONString = [
            {
                name : "Kind wechseln",
                icon: childimage,
                modal: "changechild"
            },
            {
                name : "To Do",
                icon: ("/assets/icon/to_do_colour.png"),
                modal: "todolist"
            },
            {
                name : "Kontakt",
                icon: ("/assets/icon/kontakt_colour.png"),
                modal: "cotactrequest"
            },
            {
                name : "Krankheit",
                icon: ("/assets/icon/krankmeldung-colour.png"),
                modal: "diseases"
            }
            ,
            {
                name : "Abholung",
                icon: ("/assets/icon/abholung_colour.png"),
                modal: "collector"
            },
            {
                name : "Abwesenheit",
                icon: ("/assets/icon/abwesend.png") ,
                modal: "absence"
            }
        ];

        return JSONString.map((item) =>
            (!this.state.show_switch_child && item.modal == 'changechild') ? null :
            <div container style={{ paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0, marginTop: 0}} style={{borderBottomWidth: 0, margin:0, padding:0}}  button onClick={() => this.openDialog(item.modal)}>
                <div className={'flexDrect'} style={{flexDirection: 'column', justifyContent: 'center', textAlign: 'center',alignItems: 'center',marginLeft:-15, marginRight: 10, width:80}}>
                    <div style={{shadowOffset: {width: 0, height: 2}, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5, borderRadius: 100, divAlign: 'center', marginLeft:-10, marginRight:-10, padding:0, width: 55, paddingTop: item.modal == 'changechild' ? 0: 10, paddingBottom: item.modal == 'changechild' ? 0 : 10, marginTop: item.modal == 'changechild' ? 0 : 0, alignItems: 'center', backgroundColor: '#fff',justifyContent: 'center', border: '2px solid #eee', }}>
                        <img
                            style={{width: item.modal == 'changechild' ? 50 : 30, height: item.modal == 'changechild' ? 50 : 30, borderRadius: item.modal == 'changechild' ? 100 : 0}}
                            src={item.icon}
                        />
                    </div>

                    <div style={{ justifyContent: 'center',alignItems: 'center', marginTop:10, paddingLeft:0,  paddingRight:0, }}>
                        <div style={{fontSize: 9.5, justifyContent: 'center',alignItems: 'center',color: '#888'}}>{item.name}</div>
                    </div>
                </div>
            </div>)
    }

    openGallery(item) {
        console.log(item);
        this.props.navigation.navigate('GalleryItem', {id: item.id, title: item.name});
    }

    openBoard(item) {
        console.log(item);
        //this.props.navigation.navigate('GalleryItem', {id: item.id, title: item.name});
        this.setState({boarditem: item, openboard: true});
    }

    render() {
        const {openmodal, isLoading, openboard, boarditem} = this.state;
        const deviceWidth = window.innerWidth;
        const deviceHeight = window.innerHeight;

        if(isLoading) {
            return (
                <div style={{backgroundColor: '#fff'}}>
                    <div style={{height: window.innerHeight, backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20}}>
                        Lade
                    </div>
                </div>
            )
        }

        const selectComponent = (activeWhiteboardPage) => () => this.setState({activeWhiteboardPage})

        const onModalCloseTodolist = () => {
            var someProperty = {...this.state.openmodal}
            someProperty['todolist'] = someProperty['todolist'] ? false : true;
            this.setState({openmodal: someProperty})
        }

        const onModalCloseAbsence = () => {
            var someProperty = {...this.state.openmodal}
            someProperty['absence'] = someProperty['absence'] ? false : true;
            this.setState({openmodal: someProperty})
        }

        const onModalCloseCollector = () => {
            var someProperty = {...this.state.openmodal}
            someProperty['collector'] = someProperty['collector'] ? false : true;
            this.setState({openmodal: someProperty})
        }

        const onModalCloseCotactrequest = () => {
            var someProperty = {...this.state.openmodal}
            someProperty['cotactrequest'] = someProperty['cotactrequest'] ? false : true;
            this.setState({openmodal: someProperty})
        }

        const onModalCloseDiseases = () => {
            var someProperty = {...this.state.openmodal}
            someProperty['diseases'] = someProperty['diseases'] ? false : true;
            this.setState({openmodal: someProperty})
        }

        const onModalBoardQr = () => {
            this.setState({openboard: false})
        }

        return (
            <div>
               <div style={{backgroundColor: '#fff', paddingLeft: 10}}>
                   <div style={{marginTop: 40}}>
                       <div style={{borderBottomWidth: 0}}>
                           <div>
                               <h1 style={{fontWeight: '600', fontSize: 32}}>
                                   <img
                                       style={{width: 25, height: 25, marginRight: 10}}
                                       src={("/assets/icon/plaene_colour.png")}
                                   />Dashboard</h1>
                           </div>
                       </div>
                   </div>

                   <div horizontal>
                       <div className={"flexDrect"}>
                           {
                               this.renderIcons()
                           }
                       </div>
                   </div>

                   <div>
                       <div style={{borderBottomWidth: 0, marginTop: '30px'}}>
                           <div style={{float: 'left'}}>
                               <h1 style={{fontSize: 22, fontWeight: '600'}}>
                                   <img
                                       style={{width: 26, height: 26, marginRight: 10}}
                                       src={("/assets/icon/kalender_colour.png")}
                                   />
                                   Kalender</h1>
                           </div>
                           {
                               ((this.state.calendar == null || this.state.calendar.length == 0)) ? null:<div>
                                   <Button onClick={() => window.location = '/kalender'} style={{float: 'right', marginRight: 0, paddingRight: 0, padding: 0, backgroundColor: '#f9f9f9', height: 25, borderRadius: 30}}>
                                       <span style={{color: '#81CCCE', fontSize: 12, fontWeight: '600'}}>mehr</span>
                                   </Button>
                               </div>
                           }
                           <div style={{clear: 'both'}}></div>
                       </div>
                   </div>
                   {
                       (this.state.calendar == null || this.state.calendar.length == 0)
                       ?
                        <div  style={{justifyContent: 'center', alignItems: 'center'}}><div>Keine Einträge gefunden</div></div>
                       :
                            <div>
                                {
                                    this.state.calendar.map((item1, key1) =>
                                        <div>
                                             <h3 style={{fontWeight: '600', marginTop: 10, marginBottom: 15, marginLeft: 15, color: '#8089C3'}}>{this.state.calendar[key1].date}</h3>
                                            {
                                                item1.items.map((item, key) =>
                                                    <div>

                                                        <div style={{marginLeft: 15, paddingLeft: 0}} onClick={() => window.location = '/kalender/' + item.id}>
                                                            <div style={{alignItems: 'flex-start', justifyContent: 'flex-start', position: 'absolute', marginTop: 5}}>
                                                                <div>
                                                                    <div style={{color: '#8089C3', fontWeight: '600'}}>{item.time.substr(0, item.time.length-3)}</div>
                                                                </div>
                                                            </div>
                                                            <div style={{marginLeft: 60}}>
                                                                <div style={{fontWeight: '600', fontSize: 18 }}>{item.title}</div>
                                                                <div style={{fontWeight: '400', fontSize: 14, marginTop: 8 }}>{item.text.substr(0,50)}</div>
                                                                {/* <div style={{marginTop: 20, marginBottom: 20, flexDirection: 'row', display: 'flex'}}>
                                                                {
                                                                    item.allocations.map((itemx) =>
                                                                        (itemx.image != null)
                                                                        ?
                                                                            <Thumbnail xs source={{ uri: itemx.image }} style={{marginLeft: 10, width:  55, height: 55}}/>
                                                                        :
                                                                            null
                                                                    )
                                                                }
                                                                </div> */}
                                                                <div style={{marginTop: 5, color: '#888'}}>back {item.location.name}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )
                                }

                            </div>

                   }


                   <div style={{marginTop: 30}}>
                       <div style={{borderBottomWidth: 0}}>
                           <div style={{float: 'left'}}>
                               <h1 style={{marginLeft: 0, fontSize: 22, fontWeight: '600'}}><img
                                   style={{width: 26, height: 26, marginRight: 8}}
                                   src={("/assets/icon/news-colour.png")}
                               /> Schwarzes Brett</h1>

                           </div>

                           {
                               ((this.state.whiteboard == null || this.state.whiteboard.length == 0)) ? null:<div>
                                   <Button onClick={() => window.location = '/whiteboard'} style={{float: 'right', marginRight: 0, paddingRight: 0, padding: 0, backgroundColor: '#f9f9f9', height: 25, borderRadius: 30}}>
                                       <span style={{color: '#81CCCE', fontSize: 12, fontWeight: '600'}}>mehr</span>
                                   </Button>
                               </div>
                           }
                           <div style={{clear: 'both'}}></div>
                       </div>
                   </div>


                   {
                       (this.state.whiteboard.length == 0 && this.state.whiteboardManagement.length == 0)
                           ?
                           <div  style={{alignItems: 'center', justifyContent: 'center', paddingBottom: 10}}><div>Keine Nachrichten gefunden</div></div>
                           :
                            <div>
                                <Button first style={{padding: 0, textAlign: 'center',justifyContent: 'center',alignItems: 'center', width: '49.5%', color:(this.state.activeWhiteboardPage == 0) ? '#fff':'#8089C3',borderColor:(this.state.activeWhiteboardPage == 0) ? '#8089C3':'#eee',backgroundColor: (this.state.activeWhiteboardPage == 0) ? '#8089C3':'#eee'}} active={(this.state.activeWhiteboardPage == 0) ? true:false} onClick={() => this.setState({activeWhiteboardPage: 0})}>
                                    <p style={{textAlign: 'center',justifyContent: 'center',alignItems: 'center', color:(this.state.activeWhiteboardPage == 0) ? '#fff':'#8089C3'}}>Eltern</p>
                                </Button>
                                <Button last style={{padding: 0, textAlign: 'center',justifyContent: 'center',alignItems: 'center', width: '49.5%', color:(this.state.activeWhiteboardPage == 1) ? '#fff':'#8089C3',borderColor:(this.state.activeWhiteboardPage == 1) ? '#8089C3':'#eee',backgroundColor: (this.state.activeWhiteboardPage == 1) ? '#8089C3':'#eee'}} active={(this.state.activeWhiteboardPage == 1) ? true:false}  onClick={() => this.setState({activeWhiteboardPage: 1})}>
                                    <p style={{textAlign: 'center',justifyContent: 'center',alignItems: 'center', color:(this.state.activeWhiteboardPage == 1) ? '#fff':'#8089C3'}}>Allgemein</p>
                                </Button>
                                <div style={{marginTop: 20}}>
                                    <TabPanel value={this.state.activeWhiteboardPage} index={0} style={{padding: 0}}>
                                        {
                                            this.state.whiteboardManagement.length == 0 ? <div  style={{alignItems: 'center', justifyContent: 'center', paddingBottom: 10}}><div>Keine Nachrichten gefunden</div></div> :
                                                this.state.whiteboardManagement.map((item) =>
                                                    <div activeOpacity={0.9} onClick={() => this.openBoard(item)}>
                                                        <Card style={{borderColor: '#fff', marginBottom: 10, marginLeft: 0, marginRight: 15, borderWidth: 0, borderRadius: 10, padding: 0}}>

                                                            <CardItem style={{borderRadius: 20, shadowOpacity: 0,borderColor: '#fff', borderWidth: 0}}>
                                                                <div>

                                                                    <h2 style={{fontWeight: '600', fontSize: 18, marginBottom: 0}}>{item.subject}</h2>
                                                                    <div style={{color: '#bbb'}}>

                                                                    </div>
                                                                    <div style={{marginTop: 10}}>
                                                                        {item.text}
                                                                    </div>
                                                                </div>
                                                            </CardItem>

                                                            {
                                                                (item.image.url == null) ? null
                                                                    :
                                                                    <CardItem  style={{borderRadius: 20}}>

                                                                        <div style={{backgroundImage: 'url('+item.image.url+')', backgroundSize: 'cover', width: '100%', margin: 20, height: 200, flex: 1, borderRadius: 20}}></div>
                                                                    </CardItem>
                                                            }
                                                        </Card>
                                                    </div>
                                                )
                                        }
                                    </TabPanel>
                                    <TabPanel value={this.state.activeWhiteboardPage} index={1}>
                                        {
                                            this.state.whiteboard.length == 0 ? <div  style={{alignItems: 'center', justifyContent: 'center', paddingBottom: 10}}><div>Keine Nachrichten gefunden</div></div> :
                                                this.state.whiteboard.map((item) =>
                                                    <div activeOpacity={0.9} onClick={() => this.openBoard(item)}>
                                                        <Card style={{borderColor: '#fff', marginBottom: 10, marginLeft: 0, marginRight: 15, borderWidth: 0, borderRadius: 10, padding: 0}}>

                                                            <CardItem style={{borderRadius: 20, shadowOpacity: 0,borderColor: '#fff', borderWidth: 0}}>
                                                                <div>

                                                                    <h2 style={{fontWeight: '600', fontSize: 18, marginBottom: 0}}>{item.subject}</h2>
                                                                    <div style={{color: '#bbb'}}>

                                                                    </div>
                                                                    <div style={{marginTop: 10}}>
                                                                        {item.text}
                                                                    </div>
                                                                </div>
                                                            </CardItem>

                                                            {
                                                                (item.image.url == null) ? null
                                                                    :
                                                                    <CardItem  style={{borderRadius: 20}}>

                                                                        <div style={{backgroundImage: 'url('+item.image.url+')', backgroundSize: 'contain', width: '100%', margin: 20, height: 200, flex: 1, borderRadius: 20,backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>
                                                                    </CardItem>
                                                            }
                                                        </Card>
                                                    </div>
                                                )
                                        }
                                    </TabPanel>
                                </div>
                            </div>
                   }
               </div>
                {(openmodal.todolist) ? <Todolist onModalCloseTodolist={onModalCloseTodolist} todo={this.state.todo_id} modal={openmodal.todolist}/> : null}
                {(openmodal.absence) ? <Absence onModalCloseAbsence={onModalCloseAbsence} modal={openmodal.absence}/> : null}
                {(openmodal.collector) ? <Collector onModalCloseCollector={onModalCloseCollector} modal={openmodal.collector}/> : null}
                {(openmodal.cotactrequest) ? <Contact onModalCloseCotactrequest={onModalCloseCotactrequest} modal={openmodal.cotactrequest}/> : null}
                {(openmodal.diseases) ? <Diseases onModalCloseDiseases={onModalCloseDiseases} modal={openmodal.diseases}/> : null}
                {(this.state.openboard) ? <Board item={this.state.boarditem} onModalBoardQr={onModalBoardQr} modal={true}/> : null}
            </div>
        );
    }
};

export default Dashboard;


/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {useEffect, useState} from "react";
import axios from 'axios';
import Modal from 'react-modal';

import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

class Edit_emergency_contacts extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={errors: [], isLoading: false, idx: this.props.item.id, firstname: this.props.item.firstname, lastname: this.props.item.lastname, street: this.props.item.street, zip:this.props.item.zip,city: this.props.item.city, email: this.props.item.email, phone: this.props.item.phone, mobilephone: this.props.item.mobilephone,type: this.props.item.type};
    }

    componentDidMount(){

    }

    addCollector() {
        if(this.state.firstname.length == 0) {
            this.state.errors.push("Bitte trage einen Vorname ein");
        }
        if(this.state.lastname.length == 0) {
            this.state.errors.push("Bitte trage einen Nachanmen ein");
        }
        if(this.state.street.length == 0) {
            this.state.errors.push("Bitte trage eine Straße ein");
        }
        if(this.state.zip.length == 0) {
            this.state.errors.push("Bitte trage eine PLZ ein");
        }
        if(this.state.city.length == 0) {
            this.state.errors.push("Bitte trage einen Ort ein");
        }

        this.setState({errors: this.state.errors})

        if(this.state.errors.length == 0) {

            if(this.props.child == null) {
                let data = {
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    street: this.state.street,
                    zip: this.state.zip,
                    city: this.state.city,
                    email: this.state.email,
                    phone: this.state.phone,
                    mobilephone: this.state.mobilephone,
                    type: this.state.type
                };
                this.setState({
                    errors: [],
                    isLoading: false,
                    firstname: '',
                    lastname: '',
                    street: '',
                    zip: '',
                    city: '',
                    email: '',
                    phone: '',
                    mobilephone: '',
                    type: ""
                });
                this.props.onModalCloseEditEmergencyContacts(data);
            }else{
                this.setState({isLoading: false})
                let data = {
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    street: this.state.street,
                    zip: this.state.zip,
                    city: this.state.city,
                    email: this.state.email,
                    phone: this.state.phone,
                    mobilephone: this.state.mobilephone,
                    type: this.state.type
                };


                axios.post('https://app.kitaleicht.com/api/users/my/child/' + this.props.child.id + '/update/' + this.state.idx + '/emergency_contacts',
                    data,{
                        headers: {
                            'Authorization': `Bearer ${this.props.token}`
                        }
                    })
                    .then((success) => {
                        this.setState({
                            errors: [],
                            isLoading: false,
                            firstname: '',
                            lastname: '',
                            street: '',
                            zip: '',
                            city: '',
                            email: '',
                            phone: '',
                            mobilephone: '',
                            type: ''
                        });
                        this.props.onModalCloseEditEmergencyContacts(success.data.emergency_contact);
                        alert('Kontakt erfolgreich gespeichert!');
                    })
                    .catch((error => {
                        console.log(error.response);
                        this.setState({
                            errors:  ['Fehler 500 - ein unbekanntes Problem. Wende  dich an den technischen Support.']
                        });
                    }));
            }
        }
    }

    onValueChangeType (value) {
        this.setState({
            type : value
        });
    }

    render() {
        const deviceWidth = window.innerWidth / 2 - 20;
        const deviceHeight = window.innerHeight;
        const {loading, list,errors } = this.state;

        return (
            <Modal
                isOpen={true}
                style={{justifyContent: 'flex-end',margin: 0, backgroundColor: 'transparent'}}

                deviceHeight={deviceHeight}
            >

                <div>
                    <div style={{justifyContent: "flex-end", width: '100%', alignItems: 'flex-end', marginTop: 10, marginBottom: 10, paddingRight: 10, marginLeft: 19}}>
                        <Button onClick={() => this.props.onModalCloseEditEmergencyContacts()} light style={{backgroundColor: '#e9e9e9',width: 50, height: 50, borderRadius: 100,paddingLeft: 12}}>
                            x
                        </Button>
                    </div>
                    <h1 style={{fontWeight: '600', fontSize: 22, padding:20, margin: 0, paddingTop: 10}}>{this.state.type.replace('contact','Notfallkontakt').replace('doctor','Arzt')} bearbeiten</h1>
                    <div
                        className={"container"}
                    >
                    <div>
                            <form className={'form'}>
                                <div  style={{border:0, borderBottomWidth: 0}} stackedLabel>
                                    <p style={{fontWeight: '600'}}>Vorname</p>
                                    <input value={this.state.firstname} placeholder={'Pflichtfeld'} style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                           onChange={e => this.setState({ firstname: e.target.value })}
                                    />
                                </div>
                                <div  style={{border:0, borderBottomWidth: 0}} stackedLabel>
                                    <p style={{fontWeight: '600'}}>Nachname</p>
                                    <input value={this.state.lastname} placeholder={'Pflichtfeld'} style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                           onChange={e => this.setState({ lastname: e.target.value })}/>
                                </div>
                                <div  style={{border:0, borderBottomWidth: 0}} stackedLabel>
                                    <p style={{fontWeight: '600'}}>Straße. Nr</p>
                                    <input value={this.state.street} placeholder={'Pflichtfeld'} style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                           onChange={e => this.setState({ street: e.target.value })}/>
                                </div>
                                <div  style={{border:0, borderBottomWidth: 0}} stackedLabel>
                                    <p style={{fontWeight: '600'}}>PLZ</p>
                                    <input value={this.state.zip} placeholder={'Pflichtfeld'}  style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                           onChange={e => this.setState({ zip: e.target.value })} />
                                </div>
                                <div  style={{border:0, borderBottomWidth: 0}} stackedLabel>
                                    <p style={{fontWeight: '600'}}>Stadt</p>
                                    <input value={this.state.city} placeholder={'Pflichtfeld'}  style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                           onChange={e => this.setState({ city: e.target.value })} />
                                </div>

                                <div  style={{border:0, borderBottomWidth: 0}} stackedLabel>
                                    <p style={{fontWeight: '600'}}>E-Mail</p>
                                    <input value={this.state.email} placeholder={'Optional'}  style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                           onChange={e => this.setState({ email: e.target.value })} />
                                </div>
                                <div  style={{border:0, borderBottomWidth: 0}} stackedLabel>
                                    <p style={{fontWeight: '600'}}>Telefon</p>
                                    <input value={this.state.phone} placeholder={'Optional'}  style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                           onChange={e => this.setState({ phone: e.target.value })} />
                                </div>
                                <div  style={{border:0, borderBottomWidth: 0}} stackedLabel>
                                    <p style={{fontWeight: '600'}}>Handynummer</p>
                                    <input value={this.state.mobilephone} placeholder={'Optional'}  style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                           onChange={e => this.setState({ mobilephone: e.target.value })} />
                                </div>

                                <div  style={{border:0, borderBottomWidth: 0}} stackedLabel>
                                    <p style={{fontWeight: '600'}}>Art</p>

                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={this.state.type}
                                        onChange={this.onValueChangeType.bind(this)}
                                    >
                                        <MenuItem value={''}>Bitte wählen</MenuItem>
                                        <MenuItem value={'contact'}>Notfallkontakt</MenuItem>
                                        <MenuItem value={'doctor'}>Arzt</MenuItem>
                                    </Select>
                                </div>


                                {
                                    this.state.errors.length == 0 ? null
                                        :
                                        <div style={{
                                            width: '100%',
                                            marginLeft: 0,
                                            marginRight: 0,
                                            backgroundColor: '#B22222',
                                            padding: 0
                                        }}>
                                            {
                                                this.state.errors.map((tile, key) => (
                                                    <div style={{borderBottomWidth: 0, margin: 0, padding:0}}>
                                                        <span style={{color: '#B22222'}}>{tile}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                }
                                {
                                    this.state.isLoading ?
                                        <p>Lade</p>
                                        :
                                        <Button hasText full warning rounded onClick={() => this.addCollector()} style={{width: 150, marginTop: 0,marginRight: 15, backgroundColor: '#8089C3'}}>
                                            <span style={{color: '#fff'}}>Erstellen</span>
                                        </Button>

                                }

                            </form>

                    </div>
                    </div>
                </div>



            </Modal>
        );
    }
};

export default Edit_emergency_contacts;

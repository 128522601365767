/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {Component, Fragment} from 'react';
import axios from 'axios'
import ListItem from "@material-ui/core/ListItem";
import {Button} from "@material-ui/core";

class Downloads extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={ isLoading: false, list: [], spinner: false, spinnertxt: "", customer: localStorage.getItem('customer'), token: localStorage.getItem('access_token')};
    }

    componentDidMount(){

        const onSuccess = ({data}) => {
            console.log(data.data);
            this.setState({isLoading: false, list: data.data});
        };
        const onFailure = error => {
            console.log(error.response);
            //this.setState({errors: error.response.data, isLoading: false});
        };

        axios.get('https://app.kitaleicht.com/api/users/downloadcenter/' + this.state.customer, {
            headers: {
                'Authorization': `Bearer ${this.state.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(onSuccess)
            .catch(onFailure);

    }

    render() {
        return (
            <Fragment>

                <div
                    contentInsetAdjustmentBehavior="automatic"
                    style={{margin: 0, marginTop: 10, backgroundColor: '#fff'}}
                >
                    <div style={{padding: 10, marginTop: 0}}>
                        <ListItem style={{borderBottomWidth: 0}}>
                            <div>
                                <h1 style={{fontWeight: '600', fontSize: 32}}>{/*<SvgXml width="30" height="30" xml={dairyIcon} style={{fill:'#000'}} />*/}
                                    <img
                                        style={{width: 30, height: 30}}
                                        src={("../assets/icon/download_colour.png")}
                                    />
                                    &nbsp; Downloadcenter</h1>
                            </div>
                        </ListItem>
                    </div>

                    {
                        (this.state.list == null || this.state.list.length == 0) ?
                            <p style={{textAlign: "center", marginTop: 40}}>
                                Keine Downloads gefunden
                            </p>
                            :
                            this.state.list.map((item) =>
                                <ListItem style={{ paddingLeft: 30,borderBottomWidth:  0.5, border: 0, backgroundColor: '#fff', paddingRight: 10, marginBottom: 5, borderRadius: 10}} noBorder>
                                    <div style={{flexDirection: 'column'}}>
                                        <span style={{display: 'block'}}>{item.name}</span>
                                        <span  style={{display: 'block'}}>{item.created_at}</span>
                                    </div>
                                    <div>

                                        <a href={"https://app.kitaleicht.com/"+item.path} target={'_blank'}>
                                            <img
                                                style={{width: 30, height: 30}}
                                                src={("../assets/icon/download_colour.png")}
                                            />
                                        </a>
                                    </div>
                                </ListItem>
                            )
                    }
                </div>
            </Fragment>
        );

    }
};

export default Downloads;

/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */

import React, {useEffect, useState} from "react";
import axios from "axios";
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardItem from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import Modal from 'react-modal';
import Moment from "moment";

import TextField from "@material-ui/core/TextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';

class Todolist extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={child: localStorage.getItem('child'), token: localStorage.getItem('access_token'),errors: [], assigns: null, assign: "", loadid: this.props.todo,opentodo: [], erinnern: '', datetime: new Date(), datetimetxt: '', callme: false, isLoading: true, modus: 'list', title: "", note: "", date: '', list: null, showCal: false, dateTxt: 'Datum wählen'};

    }

    componentDidMount(){

            const onSuccess1 = ({data}) => {
                this.setState({
                    assigns: data.data,
                });

            };

            const onFailure1 = error => {
                console.log(error);
                //this.setState({errors: error.response.data, isLoading: false});
            };

            axios.get('https://app.kitaleicht.com/api/users/parent/'+this.state.child+'/list', {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`
                }
            })
                .then(onSuccess1)
                .catch(onFailure1);

            const onSuccess = ({data}) => {
                console.log(data.data);
                this.setState({isLoading: false, list: data.data});
                console.log(this.props);
                if(this.state.loadid != null) {
                    this.open();
                }
            };

            const onFailure = error => {
                console.log(error);
                //this.setState({errors: error.response.data, isLoading: false});
            };

            axios.get('https://app.kitaleicht.com/api/users/todo', {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`
                }
            })
                .then(onSuccess)
                .catch(onFailure);

    }

    addToDo() {

        axios.post('https://app.kitaleicht.com/api/users/todo/create',
        {
            'title' : this.state.title,
            'text' : this.state.note,
            'date' : Moment(this.state.datetime).format('Y-MM-DD'),
            'reminder': this.state.erinnern,
            'time': this.state.datetimeTxt,
            'type': 'note',
            'assign': this.state.assign
        },{
            headers: {
                'Authorization': `Bearer ${this.state.token}`
            }
        })
            .then((success) => {
                console.log(success.data.data);
                if(success.data.data != null) {
                    this.state.list.reverse();
                    this.state.list.push(success.data.data)
                    this.state.list.reverse();
                }


                this.setState({ note: '', modus: 'list', 'title' : '', 'text' : '', 'date' : new Date(), 'reminder': null, 'time': new Date(), 'type': 'note',  dateTxt: 'Datum wählen', assign: null, 'erinnern': ''})
            })
            .catch((error => {
                console.log(error);
                if(error.response.status===500){
                    alert("Fehler 500 - ein unbekanntes Problem. Wende  dich an den technischen Support.");
                }else{
                    let errors = []

                    Object.keys(error.response.data.message).map(function(key, val) {

                        errors.push(error.response.data.message[key][0]);
                    });
                    this.setState({errors: errors});
                }
            }));
    }

    open() {
        const date = this.state.list.find(element => element.id == this.state.loadid);
        this.setState({opentodo: date, modus: 'show'})
    }

    onValueChangeerinnern (value) {
        this.setState({
            erinnern : value.target.value
        });
    }

    onValueChangeAssign (value) {
        this.setState({
            assign : value.target.value
        });
    }

    render() {
        const deviceWidth = window.innerWidth / 2 - 20;
        const deviceHeight = window.innerHeight;

        const {loading, list, errors} = this.state;

        const onDateChange = (date) => {
            //Moment.locale('de');

            this.setState({
                date: Moment(date).format('Y-MM-DD'),
                dateTxt:  Moment(date).format('DD.MM.Y'),
                showCal: true
            });
        }

        const changeStatusFinal = () => {


            axios.get('https://app.kitaleicht.com/api/users/todo/done/' + this.state.opentodo.id,{
                headers: {
                    'Authorization': `Bearer ${this.state.token}`
                }
            })
                .then((success) => {
                    this.props.onModalCloseTodolist()
                })
                .catch((error => {
                    console.log(error.response);
                }));


        }

        const open = (date, i) => {
            console.log(date);
            this.setState({opentodo: date, modus: 'show'})
        }

        const changeStatus = (date, i) => {

            var someProperty = {...this.state.list}
            date.done = date.done ? false : true;
            someProperty.i = date;

            let d = this.state.list.splice(i,1, date)

            console.log(this.state.list);
            this.setState({list: this.state.list})

            axios.get('https://app.kitaleicht.com/api/users/todo/done/' + date.id,{
                    headers: {
                        'Authorization': `Bearer ${this.state.token}`
                    }
                })
                .then((success) => {
                    this.setState({modus: 'list'});
                })
                .catch((error => {
                    console.log(error.response);
                }));


        }
        const onChangeFromTime = (event, date) => {
            //Moment.locale('de')

            this.setState({
                datetime: Moment(date, "DD.MM.YYYY HH:mm:ss"),
                dateTxt:  Moment(date, "DD.MM.YYYY HH:mm:ss").format('DD.MM.YYYY'),
                datetimeTxt:  Moment(date, "DD.MM.YYYY HH:mm:ss").format('HH:mm'),
            });
        }



        return (
            <Modal
                isOpen={true}
                style={{justifyContent: 'flex-end',margin: 0, backgroundColor: 'transparent'}}

                deviceHeight={deviceHeight}
            >
                {
                    (this.state.modus == 'add') ?

                        <div>
                            <div style={{justifyContent: "flex-end", width: '100%', alignItems: 'flex-end', marginTop: 10, marginBottom: 10, paddingRight: 10, marginLeft: 19}}>
                                <Button onClick={() => this.setState({modus: 'list'})} transparent>
                                    <div style={{color: '#81CCCE', fontSize: 20}}>zurück</div>
                                </Button>
                            </div>
                            <h1 style={{padding:20, margin: 0, paddingTop: 10, fontWeight: '600'}}>To Do erstellen</h1>
                            <div
                                className={'container'}
                            >
                                <div>
                                <form className={"form"}>
                                    <div stackedLabel style={{borderBottomWidth: 0}}>
                                        <span style={{fontWeight: '600'}}>Titel</span>
                                        <TextField placeholder={"Pflichtfeld"} value={this.state.title} style={{width: '100%', borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                   onChange={e => this.setState({ title: e.target.value })} />
                                    </div>
                                    <div stackedLabel style={{marginTop: 10, borderBottomWidth: 0}}>
                                        <p style={{fontWeight: '600', margin: 0}}>Datum</p>

                                        {
                                            (!this.state.showCal) ?
                                                <Button hasText full warning rounded onClick={() => this.setState({showCal: true})} style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, color: '#000'}}>
                                                    <div style={{color: '#81CCCE'}}>{this.state.dateTxt} {(this.state.datetimeTxt == '') ? null: this.state.datetimeTxt} </div>
                                                </Button>
                                                :
                                                <div style={{width: '100%', borderRadius: 10, backgroundColor: '#fff', marginTop: 0, marginLeft: 0}}>
                                                    <div>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardDateTimePicker
                                                                variant="inline"
                                                                format="DD.MM.yyyy HH:mm"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label=""
                                                                value={this.state.datetime}
                                                                onChange={onChangeFromTime}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                    <Button hasText full warning rounded
                                                            onClick={() => this.setState({dateTxt: this.state.dateTxt == 'Datum wählen' ? Moment(new Date()).format('DD.MM.Y HH:mm') : this.state.dateTxt, showCal: false})} style={{
                                                        borderRadius: 10,
                                                        backgroundColor: '#f9f9f9',
                                                        marginRight: 10,
                                                        marginBottom: 10,
                                                        marginTop: 10,
                                                        color: '#000'
                                                    }}>
                                                        <div
                                                            style={{color: '#81CCCE'}}>Übernehmen</div>
                                                    </Button>
                                                </div>
                                        }

                                    </div>
                                    <div stackedLabel style={{borderBottomWidth: 10}}>
                                        <span style={{fontWeight: '600'}}>Notizen</span>
                                        <textarea rowSpan={10} style={{height: 80, borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10,width: '95%'}} placeholder="Optional" value={this.state.note}
                                                  onChange={e => this.setState({ note: e.target.value })} />
                                    </div>
                                    <div style={{marginBottom: 0}} button onClick={() => {this.setState({callme: this.state.callme ? false :true})}} noBorder>

                                        <div>
                                            <div>vorher Erinnern (Optional)</div>
                                            <div style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, color: '#000'}}>

                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.erinnern}
                                                    style={{width: '100%'}}
                                                    onChange={this.onValueChangeerinnern.bind(this)}
                                                >
                                                    <MenuItem value={''}>Bitte wählen</MenuItem>
                                                    <MenuItem value={5}>5 min</MenuItem>
                                                    <MenuItem value={30}>15 min</MenuItem>
                                                    <MenuItem value={30}>30 min</MenuItem>
                                                    <MenuItem value={60}>1 Stunde</MenuItem>
                                                    <MenuItem value={120}>2 Stunde</MenuItem>
                                                    <MenuItem value={1140}>1 Tag</MenuItem>
                                                    <MenuItem value={2880}>2 Tage</MenuItem>
                                                </Select>
                                            </div>
                                        </div>


                                    </div>
                                    <div style={{marginBottom: 0}} button onClick={() => {this.setState({callme: this.state.callme ? false :true})}} noBorder>

                                        <div>
                                            <div>Zuweisen (Optional)</div>
                                            <div style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, color: '#000'}}>

                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={this.state.assign}
                                                    style={{width: '100%'}}
                                                    onChange={this.onValueChangeAssign.bind(this)}
                                                >
                                                    <MenuItem value={''}>Bitte wählen</MenuItem>
                                                    {
                                                        this.state.assigns==null? null : this.state.assigns.map((item, i) => (
                                                            <MenuItem value={item.uid}>{item.firstname} {item.lastname}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                        </div>


                                    </div>
                                    <Button hasText full warning rounded onClick={() => this.addToDo()} style={{width: 150, marginTop: 30,marginRight: 15, backgroundColor: '#8089C3'}}>
                                        <span style={{color: '#fff'}}>Erstellen</span>
                                    </Button>

                                    {
                                        errors.length == 0 ? null
                                            :
                                            <div stlye={{
                                                width: '100%',
                                                marginLeft: 0,
                                                marginRight: 0,
                                                backgroundColor: '#B22222',
                                                padding: 10
                                            }}>
                                                {
                                                    errors.map((tile, key) => (
                                                        <div stackedLabel style={{borderBottomWidth: 0, marginTop: 5,padding: 5, paddingTop:0}}>
                                                            <div style={{color: '#B22222'}}>{tile}</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    }
                                </form>

                                </div>
                            </div>
                        </div>
                        :
                        (this.state.modus == 'show') ?
                            <div>
                                <div style={{justifyContent: "flex-end", width: '100%', alignItems: 'flex-end', marginTop: 10, marginBottom: 10, paddingRight: 10, marginLeft: 19}}>
                                    <Button onClick={() => this.setState({modus: 'list'})} transparent>
                                        <div style={{color: '#81CCCE', fontSize: 20}}>zurück</div>
                                    </Button>
                                </div>
                                <div style={{paddingLeft: 25, paddingRight: 25}}>
                                    <h2 style={{fontWeight: '600', marginTop: 10, marginBottom: 10}}>{this.state.opentodo.title}</h2>

                                    <div style={{borderBottomWidth: 0, marginLeft: 0, marginBottom: 5, paddingBottom: 0}}>
                                        <div style={{flexDirection: 'row', display: 'flex'}}>
                                            <img
                                                style={{width: 20, height: 20, marginTop: 2}}
                                                src={("../../assets/icon/kalender_colour.png")}
                                            />
                                            <h1 style={{fontSize: 20, color: '#8089C3', marginLeft: 10, fontWeight: '600', marginTop: 2, marginBottom: 0}}>{Moment(this.state.opentodo.date).format('DD.MM.Y')}</h1>
                                        </div>
                                    </div>
                                    <div style={{borderBottomWidth: 0, marginLeft: 0, marginTop: 0, paddingTop: 5}}>
                                        <div style={{flexDirection: 'row', display: 'flex'}}>
                                            <img
                                                style={{width: 20, height: 20, marginTop: 2}}
                                                src={("../../assets/icon/clock.png")}
                                            />
                                            <h1 style={{fontSize: 20, color: '#8089C3', marginLeft: 10, fontWeight: '600', marginTop: 0}}>{this.state.opentodo.time.substr(0,this.state.opentodo.time.length - 3)} Uhr</h1>
                                        </div>
                                    </div>

                                    <div style={{marginTop: 10, color: '#666'}}>{this.state.opentodo.text}</div>

                                    {this.state.opentodo.reminder != null
                                        ?
                                        <div style={{marginTop: 10, color: '#666'}}>Erinnerung: {
                                            this.state.opentodo.reminder.reminder_value < 60 ?
                                                this.state.opentodo.reminder.reminder_value + ' Minuten vorher'
                                                :
                                            this.state.opentodo.reminder.reminder_value / 60 + ' Stunden vorher'
                                        } </div>
                                        :
                                        null}

                                    {(!this.state.opentodo.done) ?
                                        <Button hasText full rounded style={{width: 150, marginTop: 0,marginRight: 15, backgroundColor: '#8089C3'}} onClick={() => changeStatusFinal()}>
                                            <span style={{color: '#fff'}}>Erledigt</span>
                                        </Button>: null}

                                </div>
                            </div>
                            :
                            <div>
                                    <div style={{justifyContent: "flex-end", width: '100%', alignItems: 'flex-end', marginTop: 10, marginBottom: 10, paddingRight: 10, marginLeft: 19}}>
                                        <Button onClick={() => this.props.onModalCloseTodolist()} style={{backgroundColor: '#e9e9e9',width: 20, height: 20, borderRadius: 100,paddingLeft: 12}}>
                                            <img src={'../assets/icon/x.png'} style={{width: 10}}/>
                                        </Button>
                                    </div>
                                    <h1 style={{fontSize: 22, padding:20, margin: 0, paddingTop: 10, fontWeight: '600'}}>Meine ToDos</h1>

                                    <Button hasText transparent style={{marginBottom: 20, marginLeft: 10, marginRight: 10, justifyContent: "flex-start"}} full onClick={() => this.setState({modus: 'add'})}>
                                        <div style={{backgroundColor: '#e9e9e9', width: 25, padding: 0, marginRight: 5, borderRadius: 100}}>
                                            <img src={'../assets/icon/add.png'} style={{width: 10}}/>
                                        </div>
                                        <div style={{color: '#81CCCE', fontWeight: '600', fontSize: 18}}>ToDo erstellen</div>
                                    </Button>

                                    <div>
                                        {
                                            list == null || list.length == 0 ? <div style={{marginLeft: 15}}>Keine To Do gefunden</div> :
                                            list.map((item, i) =>
                                                <div activeOpacity={0.9} onClick={() => open(item, i)}>
                                                    <Card transparent style={{marginLeft: 15, marginBottom: 15, marginRight: 15, padding: 0,borderRadius: 10}}>
                                                        <CardItem style={{paddingBottom: 0, marginBottom: 0, flexDirection: 'row', display: 'flex'}}>
                                                            <div style={{float: 'left', width: '70%'}}><div style={{fontWeight: '600', fontSize: 20}}>{item.title}</div></div>
                                                            <div style={{textAlign: 'right', width: '29%', float: 'right'}}>
                                                                <input type={'checkbox'} onClick={() => changeStatus(item, i)} checked={item.done} style={{fontSize: 50, backgroundColor: (!item.done) ? '#fff': '#81CCCE', color: (!item.done) ? '#fff': '#81CCCE', borderColor: '#81CCCE', borderRadius: 32, width: 20, height: 20}}/>
                                                            </div>
                                                            <div style={{clear: 'both'}}></div>
                                                        </CardItem>

                                                        <CardItem style={{marginTop:0, paddingTop: 0, justifyContent: "flex-start", textAlign: 'left'}}>
                                                            <div style={{flexDirection: 'column'}}>
                                                            <div>
                                                                <small>{Moment(item.date).format('DD.MM.Y')} {item.time.substr(0,item.time.length - 3)}</small>
                                                            </div>
                                                            {item.reminder != null
                                                                ?
                                                                <div style={{marginTop: 10, color: '#666'}}>Erinnerung: {
                                                                    item.reminder.reminder_value < 60 ?
                                                                        item.reminder.reminder_value + ' Minuten vorher'
                                                                        :
                                                                        item.reminder.reminder_value / 60 + ' Stunden vorher'
                                                                } </div>
                                                                :
                                                                null}
                                                            </div>
                                                        </CardItem>
                                                    </Card>
                                                </div>
                                                    )
                                        }



                                    </div>
                                </div>

                }

            </Modal>
        );
    }
};


export default Todolist;

/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {Component, Fragment} from 'react';
import axios from 'axios'
import ListItem from "@material-ui/core/ListItem";

class Beirat extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={ isLoading: false, customer: localStorage.getItem('customer'),token: localStorage.getItem('access_token'), openchat: false, user: null, list: []};

    }

    componentDidMount(){
        const onSuccess = ({data}) => {
            console.log(data);
            this.setState({isLoading: false, list: data.data});
        };

        const onFailure = error => {
            console.log(error.response);
            //console.log(error.response);
            //this.setState({errors: error.response.data, isLoading: false});
        };

        axios.get('https://app.kitaleicht.com/api/users/advisory/' + this.state.customer, {
            headers: {
                'Authorization': `Bearer ${this.state.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(onSuccess)
            .catch(onFailure);

    }

    render() {

        if((this.state.list == null || this.state.list.length == 0)) {
            return (
                <Fragment>
                    <p style={{textAlign: "center", marginTop: 40}}>Keine Daten gefunden</p>
                </Fragment>
            );
        }else {
            return (
                <Fragment>

                    <div
                        contentInsetAdjustmentBehavior="automatic"
                        style={{marginTop: 10,  margin: 0, backgroundColor: '#fff'}}
                    >
                        <div style={{marginTop: 10, padding: 10,}}>
                            <ListItem style={{borderBottomWidth: 0}}>
                                <div>
                                    <h1 style={{fontWeight: '600', fontSize: 32}}>
                                        <img
                                            style={{width: 30, height: 30}}
                                            src={("../assets/icon/beirat_colour.png")}
                                        />
                                        &nbsp; Beirat</h1>
                                </div>
                            </ListItem>
                        </div>

                        {
                            (this.state.list == null || this.state.list.length == 0) ?
                                <p>
                                    Keinen Beirat gefunden
                                </p>
                            :
                                this.state.list.map((item) =>
                                    <ListItem avatar style={{ marginRight: 10,borderBottomWidth:  0.5, border: 0, backgroundColor: '#fff', paddingRight: 10, marginBottom: 10, borderRadius: 10}} noBorder>
                                        <div>
                                            <div style={{margin: 'auto',backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: 40, height: 40, borderRadius: 150, backgroundImage:`url(${(item.image == null) ? 'https://app.kitaleicht.com/defaultimage.jpg': item.image })` }}>

                                            </div>
                                        </div>
                                        <div style={{flexDirection: 'column', marginLeft: 30, width: 100}}>
                                            <span style={{display: 'block'}}>{item.firstname} {item.lastname}</span>
                                            <span style={{display: 'block'}}>{item.position}</span>
                                        </div>
                                        <div style={{marginLeft: 10}}>

                                                {
                                                    item.communication.map((itemx) => (
                                                        <span style={{display: 'block'}}>{itemx.value}</span>
                                                    ))
                                                }
                                        </div>
                                    </ListItem>
                            )
                        }
                    </div>
                </Fragment>
            );
        }
    }
};

export default Beirat;

/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {Component, Fragment} from 'react';
import { Button } from '@material-ui/core';
import axios from 'axios'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

class Childlist extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={ isLoading: true, token: localStorage.getItem('access_token'), data: []};
    }

    componentDidMount(){


            const onSuccess = ({data}) => {
                console.log("drin1");
                this.setState({data: data.data,isLoading: false});
                if(data.data.length == 1){
                    localStorage.setItem("count", "0");
                    localStorage.setItem("customer", ""+data.data[0].group.customer.id+"");
                    localStorage.setItem("group", JSON.stringify(data.data[0].group));

                    localStorage.setItem("child", ""+data.data[0].id+"").then(() => {
                        this.props.navigation.reset({
                            index: 0,
                            routes: [{name: 'MyTabs2'}],
                        });
                    });
                }else{
                    localStorage.setItem("count", data.data.length.toString());
                }
            };

            const onFailure = error => {
                console.log(error);

                //this.setState({errors: error.response.data, isLoading: false});
            };

            axios.get('https://app.kitaleicht.com/api/users/child', {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(onSuccess)
                .catch(onFailure);
    }

    openChild(item) {

        localStorage.setItem("childimage", item.image);
        localStorage.setItem("customer", ""+item.group.customer.id+"");
        localStorage.setItem("group", JSON.stringify(item.group));
        localStorage.setItem("child", ""+item.id+"");
        window.location = '/dashboard'
        /*xcç
        deviceStorage.save("child", ""+item.group.customer.id+"").then(() => {
            this.props.navigation.reset({
                index: 0,
                routes: [{name: 'Dashboard'}],
            });
        });*/
    }

    logout = async () => {

    };

    back = () => {
        this.props.navigation.goBack()
    }

    render() {
        const deviceWidth = window.innerWidth / 2;
        const deviceHeight = window.innerHeight / 2;

        return (
            <div>

                {
                    false == 'return'
                        ?
                    <Button style={{marginTop: 40}} title="Zurück" transparent onPress={() => this.back()} >
                      Zurück
                    </Button>
                    :
                    <Button style={{marginTop: 40}} title="Konto löschen" transparent onPress={() => this.logout()} >
                        Abmelden
                    </Button>
                }

                {
                    this.state.data == null ?
                        <div>Keine Kinder gefunden</div>
                        :
                        <div style={{marginTop: 50, marginLeft: 20}}>
                            <h1 style={{fontWeight: '600', fontSize: 32}}>Welches Kind möchtest du auswählen?</h1>
                            <List>

                                {
                                    this.state.data.length == 0 ? null :
                                        this.state.data.map((item) =>
                                            <ListItem avatar button onClick={() => {this.openChild(item)}}>
                                                <div>
                                                    <Avatar alt="Cindy Baker" src={(item.image == null) ? '': item.image}/>
                                                </div>
                                                <div>
                                                    {item.firstname}
                                                </div>
                                                <div>

                                                </div>
                                            </ListItem>
                                        )
                                }
                            </List>
                        </div>
                }
            </div>
        );
    }
};

export default Childlist;

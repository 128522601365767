/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {Component, Fragment} from 'react';
import ReactMarkdown from 'react-markdown'
import axios from 'axios'

class Impressum extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={ isLoading: false, slug: "imprint", token: localStorage.getItem('access_token'), content: []};

    }

    componentDidMount(){

        const onSuccess = ({data}) => {
            console.log(data.data.text);
            this.setState({ content: data.data })

            this.setState({isLoading: false});
        };

        const onFailure = error => {
            console.log(error.response);

            //this.setState({errors: error.response.data, isLoading: false});
        };

        axios.get('https://app.kitaleicht.com/api/users/content/' + this.state.slug)
            .then(onSuccess)
            .catch(onFailure);


    }

    render() {

        if(this.state.content.length == 0) {
            return (
                <Fragment>
                    <div style={{marginTop: 0}}>
                        <div style={{borderBottomWidth: 0}}>
                            <div>
                                <h1 style={{fontWeight: '600', fontSize: 32}}>
                                    <img
                                        style={{width: 25, height: 25}}
                                        serc={("/assets/icon/info.png")}
                                    />
                                    &nbsp; Impressum</h1>
                            </div>

                        </div>
                    </div>
                    <div style={{textAlign: "center"}}>Keine Daten gefunden</div>
                </Fragment>
            );
        }else {
            return (
                <Fragment>

                    <div
                        contentInsetAdjustmentBehavior="automatic"
                        style={{margin: 0, backgroundColor: '#fff'}}
                    >
                        <div style={{marginTop: 10}}>
                            <div style={{borderBottomWidth: 0}}>
                                <div>
                                    <h1 style={{fontWeight: '600', fontSize: 32}}>
                                        <img
                                            style={{width: 30, height: 30}}
                                            src={("/assets/icon/info.png")}
                                        />
                                        &nbsp; Impressum</h1>
                                </div>

                            </div>
                        </div>
                        <div>
                            <ReactMarkdown>{(this.state.content.text)}</ReactMarkdown>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }
};

export default Impressum;

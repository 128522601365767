/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {useEffect, useState} from "react";
import axios from 'axios';
import Modal from 'react-modal';

import { Button } from '@material-ui/core';
import Moment from "moment";
import TextField from '@material-ui/core/TextField';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/de";

class ChildProfile extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={
            imageUpload: null,
            showCal: false,
            dateTxt: 'Datum wählen',
            datetimeTxt: '',
            datetime: new Date(),
            date: new Date(),
            errors: [], id: this.props.profile.id, saving: false, isLoading: true, token: localStorage.getItem('access_token'), modus: 'list', profile: null, firstname: this.props.profile.firstname, lastname: this.props.profile.lastname, image: (this.props.profile.image == "") ? 'https://app.kitaleicht.com/defaultimage.jpg': this.props.profile.image, phone: '', email: '', street: this.props.profile.street,zip: this.props.profile.zip, city: this.props.profile.city};

        this.inputFileRef = React.createRef();
        this.onFileChange = this.handleFileChange.bind( this );
        this.onBtnClick = this.handleBtnClick.bind( this );
    }

    componentDidMount(){
        if(this.props.profile.birthday != '') {
            var bday = this.props.profile.birthday.split('-');
            this.state.dateTxt = Moment(this.props.profile.birthday).format('DD.MM.YYYY');
            this.state.datetime = Moment(this.props.profile.birthday).format('Y-MM-DD')+'T15:08:31.608Z';
            this.setState({datetime:  Moment(this.props.profile.birthday).format('Y-MM-DD')+'T15:08:31.608Z'})
        }
    }

    save() {
        this.setState({saving: true});
        axios.post('https://app.kitaleicht.com/api/users/my/child/' + this.state.id + '/update',
            {
                'firstname': this.state.firstname,
                'lastname': this.state.lastname,
                'street': this.state.street,
                'zip': this.state.zip,
                'city': this.state.city,
                'image': this.state.imageUpload,
                'birthday': this.state.date,
            }, {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((success) => {

                this.setState({saving: false});
                this.props.onModalCloseProfile();
                alert('erfolgreich!')
            })
            .catch((error => {
                console.log('error',error);
                this.setState({saving: false});

                if(error.response.status===500){
                    alert("Fehler 500 - ein unbekanntes Problem. Wende  dich an den technischen Support.")
                }else{
                    let errors = []

                    Object.keys(error.response.data.message).map(function(key, val) {
                        errors.push(error.response.data.message[key][0]);
                    });
                    this.setState({errors: errors});
                }
            }));

    }

    openDialog = () => {
        //console.loglog("hallo");
        let me = this;
        /*
        ActionSheet.show({options: ["Kamera", "Foto- und Videomediathek", "Abbrechen"],
                cancelButtonIndex: 4,
                title: ""
            },
            buttonIndex => {
                if(buttonIndex == 0) {
                    ImagePicker.openCamera({
                        multiple: false,
                        includeBase64: true
                    }).then(images => {
                        me.setState({imageUpload: images.data, image: images.path});
                    });
                }else if(buttonIndex == 1) {

                    ImagePicker.openPicker({
                        multiple: false,
                        includeBase64: true
                    }).then(images => {
                        console.log(images);
                        me.setState({imageUpload: images.data, image: images.path});
                    });
                }
            });

         */
    }

    handleBtnClick() {
        /*Collecting node-element and performing click*/
        this.inputFileRef.current.click();
    }

    handleFileChange(event){
        let image = '';
        this.getBase64(event.target.files[0], (result) => {
            this.setState({image: result});
        });

    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    render() {
        const {loading, list, errors} = this.state;
        const deviceWidth = window.innerWidth / 2 - 20;
        const deviceHeight = window.innerHeight;

        const onChangeFromTime = (event, date) => {
            //Moment.locale('de')

            this.setState({
                datetime: new Date(Date.parse(Moment(date).format('MMMM DD YYYY, HH:mm:ss'))),
                date: Moment(date).format('Y-MM-DD'),
                dateTxt:  Moment(date).format('DD.MM.YYYY'),
                datetimeTxt: '',
                showCal: true
            });
        }

        return (
            <Modal
                isOpen={true}
                style={{justifyContent: 'flex-end',margin: 0, backgroundColor: 'transparent'}}

                deviceHeight={deviceHeight}
            >

                <div>

                    <div style={{justifyContent: "flex-end", width: '100%', alignItems: 'flex-end', marginTop: 10, marginBottom: 10, paddingRight: 10, marginLeft: 19}}>
                        <Button onClick={() => this.props.onModalCloseProfile()} light style={{backgroundColor: '#f9f9f9',width: 50, height: 50, borderRadius: 100,paddingLeft: 12}}>
                            x
                        </Button>
                    </div>
                    <div
                        className={"container"}
                    >
                    <div>
                        {

                            <div style={{marginLeft: 10}}>
                                <h3 style={{marginTop: 20, marginLeft: 15}}>Mein Kind</h3>


                                <div style={{justifyContent: 'center', textAlign: 'center'}}>
                                    <div style={{margin: 'auto',backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: 150, height: 150, borderRadius: 150, backgroundImage:`url(${this.state.image == null || this.state.image == '' ? 'https://app.kitaleicht.com/defaultimage.jpg' : this.state.image})` }}>

                                    </div>

                                    <span style={{display: 'none'}}><input type="file" name="file" ref={this.inputFileRef} onChange={this.onFileChange}/></span>
                                    <Button hasText transparent style={{marginBottom: 0, marginLeft: 10, marginRight: 10, justifyContent: "flex-start"}} full onClick={this.onBtnClick}>
                                        <span style={{color: '#81CCCE', fontSize: 18, textTransform: "initial"}}>+ Bild bearbeiten</span>
                                    </Button>
                                </div>

                                <div style={{borderRadius: 20, marginTop: 10}}>
                                    <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                        <p style={{marginLeft: 10, marginBottom: 0}}>Vorname</p>
                                        <TextField name={'firstname'} value={this.state.firstname} placeholder={'Pflichtfeld'} style={{width: '98%', borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                   onChange={e => this.setState({ firstname: e.target.value })}/>
                                    </div>
                                    <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                        <p style={{marginLeft: 10, marginBottom: 0}}>Nachname</p>
                                        <TextField name={'lastname'} value={this.state.lastname} placeholder={'Pflichtfeld'} style={{width: '98%', borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                   onChange={e => this.setState({ lastname: e.target.value })}/>
                                    </div>
                                    <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                        <p style={{marginLeft: 10, marginBottom: 0}}>Geburtsdatum</p>
                                        {
                                            (!this.state.showCal) ?
                                                <Button hasText full warning rounded onClick={() => this.setState({showCal: true})} style={{borderRadius: 10, backgroundColor: '#f9f9f9',  marginBottom: 10, marginLeft: 10, marginTop: 10, color: '#000'}}>
                                                    <span style={{color: '#000'}}>{this.state.dateTxt} {this.state.datetimeTxt}</span>
                                                </Button>
                                                :
                                                <div style={{width: '100%', borderRadius: 10, backgroundColor: '#fff', marginTop: 10, marginLeft: 10}}>

                                                    <div>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                variant="inline"
                                                                format="DD.MM.yyyy HH:mm"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label=""
                                                                value={this.state.datetime}
                                                                onChange={onChangeFromTime}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                    <Button hasText full warning rounded
                                                            onClick={() => this.setState({dateTxt: this.state.dateTxt == 'Datum wählen' ? Moment(new Date()).format('DD.MM.Y') : this.state.dateTxt,showCal: false})} style={{
                                                        borderRadius: 10,
                                                        backgroundColor: '#f9f9f9',
                                                        marginRight: 10,
                                                        marginBottom: 10,
                                                        marginTop: 10,
                                                        color: '#000'
                                                    }}>
                                                        <span
                                                            style={{color: '#81CCCE'}}>Übernehmen</span>
                                                    </Button>
                                                </div>
                                        }
                                    </div>
                                    <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                        <p style={{marginLeft: 10, marginBottom: 0}}>Straße</p>
                                        <TextField name={'street'} value={this.state.street} placeholder={'Pflichtfeld'} style={{width: '98%', borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                   onChange={e => this.setState({ street: e.target.value })}/>
                                    </div>
                                    <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                        <p style={{marginLeft: 10, marginBottom: 0}}>PLZ</p>
                                        <TextField name={'zip'} value={this.state.zip} placeholder={'Pflichtfeld'} style={{width: '98%', borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                   onChange={e => this.setState({ zip: e.target.value })}/>
                                    </div>
                                    <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                        <p style={{marginLeft: 10, marginBottom: 0}}>Ort</p>
                                        <TextField name={'city'} value={this.state.city} placeholder={'Pflichtfeld'} style={{width: '98%', borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                                   onChange={e => this.setState({ city: e.target.value })}/>
                                    </div>
                                </div>
                                {
                                    this.state.saving ?
                                        <span>Laden</span>
                                        :
                                        <Button hasText full warning rounded onClick={() => this.save()} style={{width: 150, marginTop: 10, marginLeft: 10, marginRight: 15, backgroundColor: '#8089C3'}}>
                                            <span style={{color: '#fff'}}>Speichern</span>
                                        </Button>
                                }

                                {
                                    errors.length == 0 ? null
                                        :
                                        <div stlye={{
                                            width: '100%',
                                            marginLeft: 0,
                                            marginRight: 0,
                                            backgroundColor: '#B22222',
                                            padding: 10
                                        }}>
                                            {
                                                errors.map((tile, key) => (
                                                    <div stackedLabel style={{borderBottomWidth: 0, marginTop: 5,padding: 5, paddingTop:0}}>
                                                        <span style={{color: '#B22222'}}>{tile}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                }
                            </div>
                        }
                    </div>
                    </div>
                </div>



            </Modal>
        );
    }
};


export default ChildProfile;

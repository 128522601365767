/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {useEffect, useState} from "react";
import axios from 'axios';
import Modal from 'react-modal';

import { Button } from '@material-ui/core';

import Moment from "moment";
import ListItem from '@material-ui/core/ListItem';

class Showtododetails extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={errors: [], opentodo: this.props.todo, isLoading: true, token: localStorage.getItem('access_token')};

    }

    componentDidMount(){

    }


    render() {
        const {loading, list, errors} = this.state;
        const deviceWidth = window.innerWidth / 2 - 20;
        const deviceHeight = window.innerHeight;

        const changeStatusFinal = () => {
            if(!this.state.opentodo.done) {

                axios.get('https://app.kitaleicht.com/api/users/todo/done/' + this.state.opentodo.id,{
                    headers: {
                        'Authorization': `Bearer ${this.state.token}`
                    }
                })
                    .then((success) => {
                        this.props.onModalCloseTodolist()
                    })
                    .catch((error => {
                        if(error.response.status===500){
                            alert("Fehler 500 - ein unbekanntes Problem. Wende  dich an den technischen Support.")
                        }else{
                            let errors = []

                            Object.keys(error.response.data.message).map(function(key, val) {

                                errors.push(error.response.data.message[key][0]);
                            });
                            this.setState({errors: errors});
                        }
                    }));

            }
        }

        const open = (date, i) => {
            console.log(date);
            this.setState({opentodo: date, modus: 'show'})
        }


        return (
            <Modal
                isOpen={true}
                style={{justifyContent: 'flex-end',margin: 0, backgroundColor: 'transparent'}}

                deviceHeight={deviceHeight}
            >
                <div>
                    <div style={{justifyContent: "flex-end", width: '100%', alignItems: 'flex-end', marginTop: 10, marginBottom: 10, paddingRight: 10, marginLeft: 19}}>
                        <Button onClick={() => this.props.onModalCloseTodolist()} light style={{backgroundColor: '#e9e9e9',width: 40, height: 40, borderRadius: 100,paddingLeft: 12}}>
                            x
                        </Button>
                    </div>
                    <div style={{paddingLeft: 25, paddingRight: 25}}>
                        <h2 style={{fontWeight: '600', marginTop: 10, marginBottom: 10}}>{this.state.opentodo.title}</h2>

                        <ListItem style={{borderBottomWidth: 0, marginLeft: 0, marginBottom: 0, paddingBottom: 0}}>
                            <div style={{flexDirection: 'row', display: 'flex'}}>
                                <img
                                    style={{width: 20, height: 20, marginTop: 2}}
                                    src={"../../assets/icon/kalender_colour.png"}
                                />
                                <h1 style={{fontSize: 20, color: '#8089C3', marginLeft: 10, fontWeight: '600', marginBottom: 0}}>{Moment(this.state.opentodo.date).format('DD.MM.Y')}</h1>
                            </div>
                        </ListItem>
                        <ListItem style={{borderBottomWidth: 0, marginLeft: 0, marginTop: 0, paddingTop: 5}}>
                            <p style={{flexDirection: 'row', display: 'flex'}}>
                                <img
                                    style={{width: 20, height: 20, marginTop: 2}}
                                    src={"../../assets/icon/kalender_colour.png"}
                                />
                                <h1 style={{fontSize: 20, color: '#8089C3', marginLeft: 10, fontWeight: '600', marginTop: 0}}>{this.state.opentodo.time.substr(0,this.state.opentodo.time.length - 3)} Uhr</h1>
                            </p>
                        </ListItem>

                        <p style={{marginTop: 10, color: '#666'}}>{this.state.opentodo.text}</p>

                        {this.state.opentodo.reminder != null
                            ?
                            <p style={{marginTop: 10, color: '#666'}}>Erinnerung: {
                                this.state.opentodo.reminder.reminder_value < 60 ?
                                    this.state.opentodo.reminder.reminder_value + ' Minuten vorher'
                                    :
                                    this.state.opentodo.reminder.reminder_value / 60 + ' Stunden vorher'
                            } </p>
                            :
                            null}


                        <Button hasText full warning rounded onClick={() => this.changeStatusFinal()} style={{width: 150, marginTop: 0,marginRight: 15, backgroundColor: '#8089C3'}}>
                            <span style={{color: '#fff'}}>Erledigt</span>
                        </Button>

                        {
                            errors.length == 0 ? null
                                :
                                <div stlye={{
                                    width: '100%',
                                    marginLeft: 0,
                                    marginRight: 0,
                                    backgroundColor: '#B22222',
                                    padding: 10
                                }}>
                                    {
                                        errors.map((tile, key) => (
                                            <div stackedLabel style={{borderBottomWidth: 0, marginTop: 5,padding: 5, paddingTop:0}}>
                                                <span style={{color: '#B22222'}}>{tile}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                        }
                    </div>
                </div>
            </Modal>
        );
    }
};


export default Showtododetails;

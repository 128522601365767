/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {Fragment} from 'react';
import axios from 'axios';
import { Button } from '@material-ui/core';
import '../assets/login.css';
import TextField from '@material-ui/core/TextField';

const initialState = {
    email: '',
    //email: '',
    //passwort: '',
    password: '',
    errors: null,
    isAuthorized: false,
    isLoading: false,
    showToast: false
};

class Login extends React.Component<Props> {
    state = initialState;

    componentDidMount(){
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }

    forceUpdateHandler(){
        this.forceUpdate();
    };

    onemailChange = email => {
        this.setState({email});
    };

    onPasswordChange = password => {
        this.setState({password});
    };

    onPressLogin() {
        const {email, password} = this.state;
        const payload = {email, password};


        const onSuccess = ({data}) => {
            console.log(data);
            // Set JSON Web Token on success#
            if(data.image != null) {
                localStorage.setItem("profilimage", data.image);
            }

            localStorage.setItem("access_token", data.access_token);
            window.location = '/kinder'
        };

        const onFailure = error => {
            console.log(error.response.data.message);

            this.setState({errors: error.response.data.message, isLoading: false});
        };

        // Show spinner when call is made
        this.setState({isLoading: true});
        console.log(payload);
        axios.post('https://app.kitaleicht.com/api/users/auth/login', payload, {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        })
            .then(onSuccess)
            .catch(onFailure);
    }

    render() {


        return (
            <div className={{backgroundColor: '#fff'}}>
                <div className={{backgroundColor: '#fff'}}>
                    <dib
                        contentInsetAdjustmentBehavior="automatic"
                        className={'scrollView'}>

                        <div className={'body'} style={{textAlign: 'center', maxWidth: 600, margin: 'auto'}}>
                            <form className={'form'}>
                                <div stackedLabel style={{textAlign: 'left'}}>
                                    <p>E-Mail</p>
                                    <TextField style={{width: '100%', border: '1px solid #eee', borderRadius: 20, padding: 10}} placeholder={"Pflichtfeld"} value={this.state.email}
                                           onChange={e => this.setState({ email: e.target.value })}/>
                                </div>
                                <div stackedLabel  style={{textAlign: 'left'}}>
                                    <p>Passwort</p>
                                    <TextField autoCompleteType={'password'} type={'password'} secureTextEntry={true} value={this.state.password} style={{width: '100%', border: '1px solid #eee', borderRadius: 20, padding: 10}} placeholder={"Pflichtfeld"}
                                               onChange={e => this.setState({ password: e.target.value })}/>
                                </div>
                                {/*
                                <div style={{float: 'right'}}>
                                    <Button hasText transparent Left onPress={() => this.props.navigation.navigate('Password')} className={{justifyContent: 'flex-end', alignItems: 'flex-end', marginRight: 0, paddingRight: 0}}>
                                        <small className={{color: '#81CCCE', margin:0, padding: 0}}>Passwort vergessen?</small>
                                    </Button>
                                </div> */}
                                <div style={{clear: 'both'}}></div>
                                <div>
                                    <Button hasText full rounded className={"submitbutton"} onClick={() => this.onPressLogin()} style={{paddingLeft: 20, paddingRight: 20, width: '50%'}}>
                                        Anmelden
                                    </Button>
                                </div>

                                {
                                    this.state.errors != null ?
                                        Object.keys(this.state.errors).map((key, val) => <p>{this.state.errors[key]}</p>)
                                        :
                                        ''
                                }
                            </form>
                        </div>
                    </dib>
                </div>
            </div>
        );
    }
};


export default Login;

/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {Component, Fragment} from 'react';
import axios from 'axios';

class Calendar extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={ isLoading: false, calendar: [], selecteddats: [], token: localStorage.getItem('access_token'), customer: localStorage.getItem('customer')};
    }

    componentDidMount(){

            const onSuccess = ({data}) => {

                let selecteddats = {};
                let selecteddatslist = {};

                data.map((item1, key1) => {

                    item1.items.map((item, key) => {
                        selecteddats[item.date] = {marked: true}
                    })
                });
            //    console.log('itemSE',selecteddats);
                this.setState({isLoading: false, calendar: data, selecteddats: selecteddats});

            };

            const onFailure = error => {
                console.log(error.response);
                //this.setState({errors: error.response.data, isLoading: false});
            };

            axios.get('https://app.kitaleicht.com/api/users/calendar/customer/' + this.state.customer, {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(onSuccess)
                .catch(onFailure);

    }

    render() {
            return (
                <Fragment>

                    <div
                        contentInsetAdjustmentBehavior="automatic"
                        style={{padding: 10, margin: 0, backgroundColor: '#fff'}}
                    >


                        {
                            (this.state.calendar.length == 0)
                            ?
                                <p style={{marginTop: 20}}>Keine Kalendereinträge gefunden</p>
                            :
                                <div style={{marginTop: 20}}>

                                        {
                                            this.state.calendar.map((item1, key1) =>
                                                <div>
                                                    <h3 style={{fontWeight: '600', marginTop: 10, marginBottom: 15, marginLeft: 15, color: '#8089C3'}}>{this.state.calendar[key1].date}</h3>

                                                    {
                                                        item1.items.map((item, key) =>
                                                            <div>

                                                                <div style={{marginLeft: 15, paddingLeft: 0}} onClick={() => window.location = '/kalender/' + item.id}>
                                                                    <div style={{alignItems: 'flex-start', justifyContent: 'flex-start', position: 'absolute', marginTop: 5}}>
                                                                        <div>
                                                                            <div style={{color: '#8089C3', fontWeight: '600'}}>{item.time.substr(0, item.time.length-3)}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{marginLeft: 60}}>
                                                                        <div style={{fontWeight: '600', fontSize: 18 }}>{item.title}</div>
                                                                        <div style={{fontWeight: '400', fontSize: 14, marginTop: 8 }}>{item.text.substr(0,50)}</div>
                                                                        {/* <div style={{marginTop: 20, marginBottom: 20, flexDirection: 'row', display: 'flex'}}>
                                                                {
                                                                    item.allocations.map((itemx) =>
                                                                        (itemx.image != null)
                                                                        ?
                                                                            <Thumbnail xs source={{ uri: itemx.image }} style={{marginLeft: 10, width:  55, height: 55}}/>
                                                                        :
                                                                            null
                                                                    )
                                                                }
                                                                </div> */}
                                                                        <div style={{marginTop: 5, color: '#888'}}>back {item.location.name}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }

                                </div>

                        }
                    </div>
                </Fragment>
            );

    }
};

export default Calendar;

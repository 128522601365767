/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {useEffect, useState} from "react";
import axios from 'axios';
import Modal from 'react-modal';

import { Button } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import TextField from "@material-ui/core/TextField";

class Contact extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={ errors: [], isLoading: true, customer: localStorage.getItem('customer'), token: localStorage.getItem('access_token'), modus: 'list', title: "", note: "", list: null, callme: false, mailme: false};

    }

    componentDidMount(){

    }

    addToDo() {


            axios.post('https://app.kitaleicht.com/api/users/customer/'+ this.state.customer +'/contact',
                {
                    'chat': this.state.mailme,
                    'recall': this.state.callme,
                    'phone': this.state.title,
                    'reason': this.state.note,
                }, {
                    headers: {
                        'Authorization': `Bearer ${this.state.token}`
                    }
                })
                .then((success) => {
                    console.log('success',success);
                    this.props.onModalCloseCotactrequest();
                    alert('Kontaktanfrage erfolgreich verschickt!')
                })
                .catch((error => {
                    console.log('success',error.response);
                    if(error.response.status===500){
                        alert("Fehler 500 - ein unbekanntes Problem. Wende  dich an den technischen Support.")
                    }else{
                        let errors = []

                        Object.keys(error.response.data.message).map(function(key, val) {

                            errors.push(error.response.data.message[key][0]);
                        });
                        console.log(errors);
                        this.setState({errors: errors});
                    }
                }));

    }

    render() {
        const {loading, list,errors} = this.state;
        const deviceWidth = window.innerWidth / 2 - 20;
        const deviceHeight = window.innerHeight;

        return (
            <Modal
                isOpen={true}
                style={{justifyContent: 'flex-end',margin: 0, backgroundColor: 'transparent'}}

                deviceHeight={deviceHeight}
            >

                <div>
                    <div style={{justifyContent: "flex-end", width: '100%', alignItems: 'flex-end', marginTop: 10, marginBottom: 10, paddingRight: 10, marginLeft: 19}}>
                        <Button onClick={() => this.props.onModalCloseCotactrequest()} light style={{backgroundColor: '#e9e9e9',width: 40, height: 40, borderRadius: 100,paddingLeft: 12}}>
                            x
                        </Button>
                    </div>
                    <h1 style={{fontSize: 22, padding:20, margin: 0, paddingTop: 10, fontWeight: '600'}}>Bitte um Kontakt</h1>
                    <div
                        className={"container"}
                    >
                        <div>

                        <form style={{paddingLeft: 20, paddingRight: 20}}>
                            <ListItem style={{marginBottom: 0, padding: 0, margin: 0, height: 40}} onClick={() => {this.setState({callme: this.state.callme ? false :true})}} noBorder>
                                <input type={"checkbox"} onClick={() => {this.setState({callme: this.state.callme ? false :true})}} checked={this.state.callme} style={{width:'auto',backgroundColor: (!this.state.callme) ? '#fff': '#81CCCE', borderColor: '#81CCCE'}}/>
                                <p>
                                    <span>Rückruf</span>
                                </p>
                            </ListItem>
                            <ListItem style={{marginBottom: 0, padding: 0, margin: 0, height: 40}} onClick={() => {this.setState({mailme: !this.state.mailme})}} noBorder>
                                <input type={"checkbox"} onClick={() => {this.setState({mailme: !this.state.mailme})}}  checked={this.state.mailme} style={{width:'auto',backgroundColor: (!this.state.mailme) ? '#fff': '#81CCCE', borderColor: '#81CCCE'}}/>
                                <p>
                                    <span>Chat</span>
                                </p>
                            </ListItem>
                            <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                <p style={{fontWeight: '600', color: '#000'}}>Telefonnummer</p>
                                <p style={{fontSize: 12, margin:0, padding:0}}>Wir nutzen bei leerer Auswahl deine Rufnummer aus deinem Profil</p>
                                <TextField value={this.state.title}  style={{width: "100%", borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, paddingLeft: 10}}
                                       onChange={e => this.setState({ title: e.target.value })} />
                            </div>
                            <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                <p style={{fontWeight: '600', color: '#000'}}>Grund</p>
                                <textarea rowSpan={5} style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginLeft: 0, marginRight: 10, marginBottom: 10, marginTop: 10,width: '98%', height: 200}} placeholder="Pflichtfeld" value={this.state.note}
                                          onChange={e => this.setState({ note: e.target.value })}  />
                            </div>
                            <Button hasText full warning rounded onClick={() => this.addToDo()} style={{width: 150, marginTop: 0,marginRight: 15, backgroundColor: '#8089C3'}}>
                                <span style={{color: '#fff'}}>Erstellen</span>
                            </Button>

                            {
                                errors.length == 0 ? null
                                    :
                                    <div stlye={{
                                        width: '100%',
                                        marginLeft: 0,
                                        marginRight: 0,
                                        backgroundColor: '#B22222',
                                        padding: 10
                                    }}>
                                        {
                                            errors.map((tile, key) => (
                                                <div stackedLabel style={{borderBottomWidth: 0, marginTop: 5,padding: 5, paddingTop:0}}>
                                                    <span style={{color: '#B22222'}}>{tile}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                            }
                        </form>

                        </div>
                    </div>
                </div>



            </Modal>
        );
    }
};


export default Contact;

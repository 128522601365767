/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {useEffect, useState} from "react";
import axios from 'axios';
import Modal from 'react-modal';

import { Button } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Moment from "moment";
import ListItem from '@material-ui/core/ListItem';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/de";

class Diseases extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={child: localStorage.getItem('child'), errors: [], datetimeBegin: new Date(),datetime: new Date(), reasonTxt: '', allSicks: [], others: '', sick_reasons_id: null, own_sick: '', isLoading: true, token: localStorage.getItem('access_token'), modus: 'list', title: "", note: "", list: null, showCal: false, dateTxtBegin: 'Datum wählen',dateTxt: 'Datum wählen'};

    }

    componentDidMount(){

            axios.get('https://app.kitaleicht.com/api/users/child/' + this.state.child + '/sickreasons/most',
               {
                    headers: {
                        'Authorization': `Bearer ${this.state.token}`
                    }
                })
                .then((success) => {
                    console.log(success.data.data);
                    this.setState({'allSicks': success.data.data});
                })
                .catch((error => {

                }));

    }

    addToDo() {
        var data = {};
        if(this.state.reason == 'own') {
            data = {
                'own' : this.state.reason == 'own' ? true : false,
                'own_sick_reasons': this.state.own_sick,
                'date_to' : Moment(this.state.datetime).format('Y-MM-DD'),
                'date_from' : Moment(this.state.datetimeBegin).format('Y-MM-DD'),
                'others' : this.state.others,
            };
        }else {
            data = {
                'own' : this.state.reason == 'own' ? true : false,
                'sick_reasons_id' : this.state.reason == 'own' ? null : this.state.sick_reasons_id,
                'date_to' : Moment(this.state.datetime).format('Y-MM-DD'),
                'date_from' : Moment(this.state.datetimeBegin).format('Y-MM-DD'),
                'others' : this.state.others,
            };
        }

        axios.post('https://app.kitaleicht.com/api/users/child/' + this.state.child + '/sick/create',
            data,{
                headers: {
                    'Authorization': `Bearer ${this.state.token}`
                }
            })
            .then((success) => {
                this.props.onModalCloseDiseases();
                alert('Krankheit erfolgreich gemeldet!')
            })
            .catch((error => {
                if(error.response.status===500){
                    alert("Fehler 500 - ein unbekanntes Problem. Wende  dich an den technischen Support.")
                }else{
                    let errors = []

                    Object.keys(error.response.data.message).map(function(key, val) {

                        errors.push(error.response.data.message[key][0]);
                    });
                    this.setState({errors: errors});

                }
            }));
    }

    render() {
        const {loading, list, errors} = this.state;
        const deviceWidth = window.innerWidth / 2 - 20;
        const deviceHeight = window.innerHeight;

        const onDateChange = (date) => {
            //Moment.locale('de');


            this.setState({
                date: Moment(date).format('Y-MM-DD'),
                dateTxt:  Moment(date).format('DD.MM.Y'),
                showCal: true
            });
        }

        const onChangeFromTime = (event, date) => {
            //Moment.locale('de')

            this.setState({
                datetime: Moment(date, "DD.MM.YYYY HH:mm"),
                dateTxt:  Moment(date, "DD.MM.YYYY HH:mm:ss").format('DD.MM.Y'),
                datetimeTxt:  Moment(date, "DD.MM.YYYY HH:mm:ss").format('HH:mm'),
                showCal: true
            });
        }

        const onChangeFromTimeBegin = (event, date) => {
            //Moment.locale('de')

            this.setState({
                datetimeBegin: Moment(date, "DD.MM.YYYY HH:mm"),
                dateTxtBegin:  Moment(date, "DD.MM.YYYY HH:mm:ss").format('DD.MM.Y'),
                datetimeTxtBegin:  Moment(date, "DD.MM.YYYY HH:mm:ss").format('HH:mm'),
                showCalBegin: true
            });
        }

        return (
            <Modal
                isOpen={true}
                style={{justifyContent: 'flex-end',margin: 0, backgroundColor: 'transparent'}}

                deviceHeight={deviceHeight}
            >

                <div>
                    <div style={{justifyContent: "flex-end", width: '100%', alignItems: 'flex-end', marginTop: 10, marginBottom: 10, paddingRight: 10, marginLeft: 19}}>
                        <Button onClick={() => this.props.onModalCloseDiseases()} light style={{backgroundColor: '#e9e9e9',width: 40, height: 40, borderRadius: 100,paddingLeft: 12}}>
                            x
                        </Button>
                    </div>
                    <h1 style={{fontSize: 22, padding:20, margin: 0, paddingTop: 10, fontWeight: '600'}}>Krankmeldung erstellen</h1>
                    <div
                        className={"container"}
                    >
                        <div>

                        {
                            (this.state.modus == 'add') ?
                                <form className={"form"}>
                                    <div stackedLabel style={{borderBottomWidth: 0}}>
                                        <span>Grund</span>
                                    </div>

                                    <ListItem stackedLabel button onClick={() => {this.setState({reasonTxt: 'Eigene',reason: 'own', 'modus': 'list'})}}>
                                        <ListItemText primary="Eigene"/>
                                    </ListItem>

                                    {
                                        this.state.allSicks.map((item, i) => (
                                            <ListItem stackedLabel button onClick={() => {this.setState({sick_reasons_id: item.id,reasonTxt: item.name, reason: item.id, 'modus': 'list'})}}>
                                                <ListItemText
                                                    primary={item.name}
                                                />
                                            </ListItem>
                                        ))
                                    }
                                </form>
                                :
                                <form className={"form"}>
                                    <div stackedLabel style={{borderBottomWidth: 0}}>
                                        <Button hasText full warning rounded
                                                onClick={() => this.setState({modus: 'add'})} style={{
                                            borderRadius: 10,
                                            backgroundColor: '#f9f9f9',
                                            marginBottom: 10,
                                            marginTop: 10,
                                            color: '#000'
                                        }}>
                                            {
                                                this.state.reasonTxt != ''
                                                ?
                                                    <span style={{color: '#000'}}>{this.state.reasonTxt}</span>
                                                    :
                                                    <div>
                                                        <span style={{color: '#000'}}>Krankheit suchen</span>
                                                    </div>
                                            }
                                        </Button>
                                    </div>
                                    {
                                        (this.state.reason == 'own')
                                            ?
                                            <div stackedLabel style={{borderBottomWidth: 0}}>
                                                <p>Krankheit angeben</p>
                                                <textarea rowSpan={5} style={{
                                                    borderRadius: 10,
                                                    backgroundColor: '#f9f9f9',
                                                    marginRight: 10,
                                                    marginBottom: 10,
                                                    marginTop: 0,
                                                    height: 80,
                                                    width: '98%'
                                                }} placeholder="Pflichtfeld" value={this.state.own_sick}
                                                          onChange={own_sick => this.setState({own_sick})}/>
                                            </div>
                                            :
                                            null
                                    }

                                    <div stackedLabel style={{borderBottomWidth: 0}}>
                                        <p style={{fontWeight: '600', color: '#000'}}>Von</p>

                                        {
                                            (!this.state.showCalBegin) ?
                                                <Button hasText full warning rounded
                                                        onClick={() => this.setState({showCalBegin: true})} style={{
                                                    borderRadius: 10,
                                                    backgroundColor: '#f9f9f9',
                                                    marginRight: 10,
                                                    marginBottom: 10,
                                                    marginTop: 0,
                                                    color: '#000'
                                                }}>
                                                    <span style={{color: '#000'}}>{this.state.dateTxtBegin}</span>
                                                </Button>
                                                :
                                                <div style={{width: '100%', borderRadius: 10, backgroundColor: '#fff', marginTop: 0, marginLeft: 0}}>

                                                    <div>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                variant="inline"
                                                                format="DD.MM.yyyy HH:mm"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label=""
                                                                value={this.state.datetimeBegin}
                                                                onChange={onChangeFromTimeBegin}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>

                                                    <Button hasText full warning rounded
                                                            onClick={() => this.setState({dateTxtBegin: this.state.dateTxtBegin == 'Datum wählen' ? Moment(new Date()).format('DD.MM.Y') : this.state.dateTxtBegin, showCalBegin: false})} style={{
                                                        borderRadius: 10,
                                                        backgroundColor: '#f9f9f9',
                                                        marginRight: 10,
                                                        marginBottom: 10,
                                                        marginTop: 10,
                                                        color: '#000'
                                                    }}>
                                                        <span
                                                            style={{color: '#81CCCE'}}>Übernehmen</span>
                                                    </Button>
                                                </div>
                                        }

                                    </div>

                                    <div stackedLabel style={{borderBottomWidth: 0}}>
                                        <p style={{fontWeight: '600', color: '#000'}}>Bis einschließlich</p>

                                        {
                                            (!this.state.showCal) ?
                                                <Button hasText full warning rounded
                                                        onClick={() => this.setState({showCal: true})} style={{
                                                    borderRadius: 10,
                                                    backgroundColor: '#f9f9f9',
                                                    marginRight: 10,
                                                    marginBottom: 10,
                                                    marginTop: 0,
                                                    color: '#000'
                                                }}>
                                                    <span style={{color: '#000'}}>{this.state.dateTxt}</span>
                                                </Button>
                                                :
                                                <div style={{width: '100%', borderRadius: 10, backgroundColor: '#fff', marginTop: 0, marginLeft: 0}}>

                                                    <div>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                variant="inline"
                                                                format="DD.MM.yyyy"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label="Date picker inline"
                                                                value={this.state.datetime}
                                                                onChange={onChangeFromTime}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>


                                                    <Button hasText full warning rounded
                                                            onClick={() => this.setState({dateTxt: this.state.dateTxt == 'Datum wählen' ? Moment(new Date()).format('DD.MM.Y') : this.state.dateTxt, showCal: false})} style={{
                                                        borderRadius: 10,
                                                        backgroundColor: '#f9f9f9',
                                                        marginRight: 10,
                                                        marginBottom: 10,
                                                        marginTop: 10,
                                                        color: '#000'
                                                    }}>
                                                        <span
                                                            style={{color: '#81CCCE'}}>Übernehmen</span>
                                                    </Button>
                                                </div>
                                        }

                                    </div>

                                    <div stackedLabel style={{borderBottomWidth: 0}}>
                                        <p style={{fontWeight: '600', color: '#000'}}>Sonstiges</p>
                                        <textarea rowSpan={5} style={{
                                            borderRadius: 10,
                                            backgroundColor: '#f9f9f9',
                                            marginRight: 10,
                                            marginBottom: 10,
                                            height: 80,
                                            marginTop: 0,
                                            width: '98%'
                                        }} placeholder="Optional" value={this.state.note}
                                                  onChange={e => this.setState({ note: e.target.value })}
                                        />
                                    </div>
                                    <Button hasText full warning rounded onClick={() => this.addToDo()} style={{width: 150, marginTop: 0,marginRight: 15, backgroundColor: '#8089C3'}}>
                                        <span style={{color: '#fff'}}>Erstellen</span>
                                    </Button>

                                    {
                                        errors.length == 0 ? null
                                            :
                                            <div stlye={{
                                                width: '100%',
                                                marginLeft: 0,
                                                marginRight: 0,
                                                backgroundColor: '#B22222',
                                                padding: 10
                                            }}>
                                                {
                                                    errors.map((tile, key) => (
                                                        <div stackedLabel style={{borderBottomWidth: 0, marginTop: 5,padding: 5, paddingTop:0}}>
                                                            <span style={{color: '#B22222'}}>{tile}</span>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                    }
                                </form>
                        }
                        </div>
                    </div>
                </div>



            </Modal>
        );
    }
};


export default Diseases;

/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {useEffect, useState} from "react";
import axios from 'axios';
import Modal from 'react-modal';

import { Button } from '@material-ui/core';
import Moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/de";
import {KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

class Absence extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={child: localStorage.getItem('child'), token: localStorage.getItem('access_token'), absence_reasons_id: null, reasons: [],errors: [], reasonTxt: 'Bitte wählen',reason: 'Bitte wählen', datetime: new Date(), datetime1: new Date(), datetimetxt: '', isLoading: true, modus: 'list', title: "", note: "", list: null, showCal1: false, dateTxt1: 'Datum wählen', showCal: false, dateTxt: 'Datum wählen'};

    }

    componentDidMount(){

        const onSuccess = ({data}) => {
            this.setState({
                isLoading: false,
                list: data.data,
            });
        };

        const onFailure = error => {

            if(error.response.status===500){
                alert("Fehler 500 - ein unbekanntes Problem. Wende  dich an den technischen Support.");
            }else{
                let errors = []

                Object.keys(error.response.data.message).map(function(key, val) {

                    errors.push(error.response.data.message[key][0]);
                });
                this.setState({errors: errors});

            }
        };

        const onSuccess1 = ({data}) => {
            this.setState({
                isLoading: false,
                reasons: data.data,
            });
        };
        axios.get('https://app.kitaleicht.com/api/users/child/' + this.state.child + '/absence/list', {
            headers: {
                'Authorization': `Bearer ${this.state.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(onSuccess)
            .catch(onFailure);

        axios.get('https://app.kitaleicht.com/api/users/child/' + this.state.child + '/absence/load', {
            headers: {
                'Authorization': `Bearer ${this.state.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(onSuccess1)
            .catch(onFailure);

    }

    addToDo() {
        let json = null;
        if(this.state.reason == 'own') {
            json = {
                'date_from' : Moment(this.state.datetime).format('Y-MM-DD HH:mm:ss'),
                'date_to' : Moment(this.state.datetime1).format('Y-MM-DD'),
                'phone' : "0",
                'own_absence_reason' : this.state.note,
                'own' : true
            }
        }else{
            json = {
                'date_from' : Moment(this.state.datetime).format('Y-MM-DD HH:mm:ss'),
                'date_to' : Moment(this.state.datetime1).format('Y-MM-DD'),
                'phone' : "0",
                'absence_reasons_id' : this.state.absence_reasons_id,
                'own' : false
            }
        }

        axios.post('https://app.kitaleicht.com/api/users/child/' + this.state.child + '/absence/create',
            json,{
                headers: {
                    'Authorization': `Bearer ${this.state.token}`
                }
            })
            .then((success) => {
                this.props.onModalCloseAbsence();
                alert('Abwesenheit erfolgreich gemeldet!');
            })
            .catch((error => {
                if(error.response.status===500){
                    alert("Fehler 500 - ein unbekanntes Problem. Wende  dich an den technischen Support.")
                }else{
                    let errors = []

                    Object.keys(error.response.data.message).map(function(key, val) {

                        errors.push(error.response.data.message[key][0]);
                    });
                    this.setState({errors: errors});

                }
            }));
    }

    render() {
        const {loading, list, errors} = this.state;

        const onChangeFromime = (event, date) => {
            this.setState({
                datetime: Moment(date, "DD.MM.YYYY HH:mm"),
                dateTxt:  Moment(date, "DD.MM.YYYY HH:mm").format('DD.MM.Y'),
                datetimeTxt:  Moment(date, "DD.MM.YYYY HH:mm").format('HH:mm'),
                showCal: true
            });
        }

        const onChangeToTime = (event, date) => {
            //Moment.locale('de')

            this.setState({
                datetime1: Moment(date, "DD.MM.YYYY HH:mm"),
                dateTxt1:  Moment(date, "DD.MM.YYYY").format('DD.MM.Y'),
                datetimeTxt1:  null,
                showCal1: true
            });
        }



        const onChangeFromTime1 = (date) => {
            //Moment.locale('de');

            this.setState({
                datetime: Moment(date).format('HH:mm:ss'),
                datetimeTxt:  Moment(date).format('HH:mm'),
            });
        }

        const deleteCollector = (item, id) => {
            axios.get('https://app.kitaleicht.com/api/users/child/' + this.state.child + '/absence/remove/' + item.id,{
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then((success) => {

                    this.state.list.splice(id, 1);

                    let list = this.state.list;
                    this.setState({list: list});
                })
                .catch((error => {
                    console.log(error.response);
                }));
        }

        return (
            <Modal
                isOpen={true}
                style={{justifyContent: 'flex-end',margin: 0, backgroundColor: 'transparent'}}

            >

                <div>
                    <div style={{justifyContent: "flex-end", width: '100%', alignItems: 'flex-end', marginTop: 10, marginBottom: 10, paddingRight: 10, marginLeft: 19}}>
                        <Button onClick={() => this.props.onModalCloseAbsence()} light style={{backgroundColor: '#e9e9e9',width: 40, height: 40, borderRadius: 100,paddingLeft: 12}}>
                            x
                        </Button>
                    </div>
                    <h1 style={{fontSize: 22, padding:20, margin: 0, paddingTop: 10, fontWeight: '600'}}>Abwesenheitsmeldung</h1>
                    <div
                        className={"container"}
                    >
                    <div>

                        {
                            (this.state.modus == 'add') ?
                                <form className={'form'}>
                                    <div stackedLabel style={{borderBottomWidth: 0}}>
                                        <span>Grund</span>
                                    </div>

                                    <div stackedLabel button onClick={() => {this.setState({reason: 'own', 'modus': 'show'})}}>
                                        <div>
                                            <span>Eigene</span>
                                        </div>
                                        <div>
                                            <input type={'radio'} selected={(this.state.reason == 'own') ? true:false} />
                                        </div>
                                    </div>
                                    {
                                        this.state.reasons.map((item, i) => (
                                            <div stackedLabel button onClick={() => {this.setState({absence_reasons_id: item.id,reasonTxt: item.name, reason: item.id, 'modus': 'show'})}}>
                                                <div>
                                                    <span>{item.name}</span>
                                                </div>
                                                <div>
                                                    <input type={'radio'} selected={(this.state.reason == item.id) ? true:false} />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </form>
                                :
                                (this.state.modus == 'list') ?
                                    <form className={"form"} style={{marginLeft: 10, marginRight: 10}}>
                                        <div avatar noBorder style={{marginBottom: 20, backgroundColor: '#f9f9f9', width: 280, padding: 10, borderRadius: 20}}  onClick={() => {this.setState({modus: 'show'})}}>
                                            <span style={{width: 40, borderBottomWidth: 0, border: 0, paddingTop: 10, paddingLeft: 10, paddingRight: 10, borderRadius: 100, height: 40}}>
                                                +
                                            </span>
                                            <span>
                                                <span style={{color:'#81CCCE', fontWeight: '600'}}>neue Abwesenheit hinzufügen</span>
                                            </span>
                                        </div>
                                        {
                                            (this.state.list == null || this.state.list.length == 0)
                                                ?
                                                <span style={{textAlign: "center", marginTop: 40}}>Keine Abwesenheiten gefunden</span>
                                                :
                                                this.state.list.map((item, i) =>

                                                            <div style={{borderBottomWidth: 0, border: 1, backgroundColor: '#fff', paddingRight: 10, marginBottom: 10, borderRadius: 3}} >
                                                                <div>
                                                                    <span style={{marginBottom: 5}}>{item.reason}</span>
                                                                    <span style={{fontSize:13, paddingLeft: 5}}>{Moment(item.date_from).format('DD MMM HH:mm')} Uhr bis {Moment(item.date_to).format('d MMM YYYY')}</span>
                                                                </div>
                                                            </div>

                                                )
                                        }
                                    </form>
                                    :
                                        <form className={"form"}>
                                            <div stackedLabel style={{borderBottomWidth: 0}}>
                                                <p style={{fontWeight: '600', margin: 0}}>Abwesend ab</p>

                                                {
                                                    (!this.state.showCal) ?
                                                        <Button hasText full warning rounded
                                                                onClick={() => this.setState({showCal: true})} style={{
                                                            borderRadius: 10,
                                                            backgroundColor: '#f9f9f9',
                                                            marginRight: 10,
                                                            marginBottom: 10,
                                                            marginTop: 10,
                                                            color: '#000'
                                                        }}>
                                                            <span
                                                                style={{color: '#81CCCE'}}>{this.state.dateTxt} {(this.state.dateTxt == 'Datum wählen') ? null : Moment(this.state.datetime).format('HH:mm')}</span>
                                                        </Button>
                                                        :
                                                        <div style={{width: '100%', borderRadius: 10, backgroundColor: '#fff', marginTop: 10, marginLeft: 0}}>

                                                            <div>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <KeyboardDateTimePicker
                                                                        variant="inline"
                                                                        format="DD.MM.yyyy HH:mm"
                                                                        margin="normal"
                                                                        id="date-picker-inline"

                                                                        value={this.state.datetime}
                                                                        onChange={onChangeFromime}
                                                                        KeyboardButtonProps={{
                                                                            'aria-label': 'change date',
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                            <Button hasText full warning rounded
                                                                    onClick={() => this.setState({dateTxt: this.state.dateTxt == 'Datum wählen' ? Moment(new Date()).format('DD.MM.Y') : this.state.dateTxt, showCal: false})} style={{
                                                                borderRadius: 10,
                                                                backgroundColor: '#f9f9f9',
                                                                marginRight: 10,
                                                                marginBottom: 10,
                                                                marginTop: 10,
                                                                color: '#000'
                                                            }}>
                                                                <span
                                                                    style={{color: '#81CCCE'}}>Übernehmen</span>
                                                            </Button>
                                                        </div>
                                                }



                                            </div>
                                            <div stackedLabel style={{borderBottomWidth: 0}}>
                                                <p style={{fontWeight: '600', margin: 0}}>Bis einschließlich (optional)</p>

                                                {
                                                    (!this.state.showCal1) ?
                                                        <Button hasText full warning rounded
                                                                onClick={() => this.setState({dateTxt1: this.state.dateTxt1 == 'Datum wählen' ? Moment(new Date()).format('DD.MM.Y') : this.state.dateTxt1, showCal1: true})} style={{
                                                            borderRadius: 10,
                                                            backgroundColor: '#f9f9f9',
                                                            marginRight: 10,
                                                            marginBottom: 10,
                                                            marginTop: 10,
                                                            color: '#000'
                                                        }}>
                                                            <span style={{color: '#81CCCE'}}>{this.state.dateTxt1}</span>
                                                        </Button>
                                                        :
                                                        <div style={{width: '100%', borderRadius: 10, backgroundColor: '#fff', marginTop: 10, marginLeft: 0}}>
                                                            <div>
                                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                                    <KeyboardDatePicker
                                                                        variant="inline"
                                                                        format="DD.MM.yyyy"
                                                                        margin="normal"
                                                                        id="date-picker-inline"
                                                                        value={this.state.datetime1}
                                                                        onChange={onChangeToTime}
                                                                        KeyboardButtonProps={{
                                                                            'aria-label': 'change date',
                                                                        }}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </div>

                                                            <Button hasText full warning rounded
                                                                    onClick={() => this.setState({showCal1: false})} style={{
                                                                borderRadius: 10,
                                                                backgroundColor: '#f9f9f9',
                                                                marginRight: 10,
                                                                marginBottom: 10,
                                                                marginTop: 10,
                                                                color: '#000'
                                                            }}>
                                                                <span
                                                                    style={{color: '#81CCCE'}}>Übernehmen</span>
                                                            </Button>
                                                        </div>
                                                }

                                            </div>
                                            <div stackedLabel style={{borderBottomWidth: 0}}>
                                                <p style={{fontWeight: '600', margin: 0}}>Grund der Abwesenheit</p>
                                                <Button hasText full warning rounded
                                                        onClick={() => this.setState({modus: 'add'})} style={{
                                                    borderRadius: 10,
                                                    backgroundColor: '#f9f9f9',
                                                    marginRight: 10,
                                                    marginBottom: 10,
                                                    marginTop: 10,
                                                    color: '#000'
                                                }}>
                                                    <span style={{color: '#81CCCE'}}>{this.state.reason == 'own' ? 'Eigene':this.state.reasonTxt }</span>
                                                </Button>
                                            </div>
                                            {
                                                (this.state.reason == 'own') ?

                                                <div stackedLabel style={{borderBottomWidth: 0}}>
                                                    <span>Grund angeben</span>
                                                    <textarea rowSpan={5} style={{
                                                        borderRadius: 10,
                                                        backgroundColor: '#f9f9f9',
                                                        marginLeft: 0,
                                                        marginRight: 10,
                                                        marginBottom: 10,
                                                        marginTop: 10,
                                                        minHeight: 100,
                                                        width: '98%'
                                                    }} placeholder="Optional" value={this.state.note}
                                                              onChange={e => this.setState({ note: e.target.value })}
                                                    />
                                                </div>
                                                :
                                                null
                                            }
                                            <Button hasText full warning rounded onClick={() => this.addToDo()} style={{width: 150, marginTop: 0,marginRight: 15, backgroundColor: '#8089C3'}}>
                                                <span style={{color: '#fff'}}>Erstellen</span>
                                            </Button>
                                            {
                                                errors.length == 0 ? null
                                                    :
                                                    <div stlye={{
                                                        width: '100%',
                                                        marginLeft: 0,
                                                        marginRight: 0,
                                                        backgroundColor: '#B22222',
                                                        padding: 10
                                                    }}>
                                                        {
                                                            errors.map((tile, key) => (
                                                                <div stackedLabel style={{borderBottomWidth: 0, marginTop: 5,padding: 5, paddingTop:0}}>
                                                                    <span style={{color: '#B22222'}}>{tile}</span>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                            }
                                        </form>
                        }

                    </div>
                </div>
                </div>



            </Modal>
        );
    }
};


export default Absence;

/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {useEffect, useState} from "react";
import Modal from 'react-modal';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardItem from '@material-ui/core/CardContent';
import Moment from "moment";


class Board extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={ isLoading: true, token: localStorage.getItem('access_token'), modus: 'list', title: "", note: "", list: null};

    }

    componentDidMount(){

    }

    render() {
        const {loading, list} = this.state;

        const deviceWidth = window.innerWidth / 2 - 20;
        const deviceHeight = window.innerHeight;

        return (
            <Modal
                isOpen={true}
                style={{justifyContent: 'flex-end',margin: 0, backgroundColor: 'transparent'}}

                deviceHeight={deviceHeight}
            >

                <div>
                    <div style={{justifyContent: "flex-end", width: '100%', alignItems: 'flex-end', marginTop: 10, marginBottom: 10, paddingRight: 10, marginLeft: 19}}>
                        <Button onClick={() => this.props.onModalBoardQr()} light style={{backgroundColor: '#e9e9e9',width: 40, height: 40, borderRadius: 100,paddingLeft: 12}}>
                            x
                        </Button>
                    </div>

                    <div>
                        <Card transparent style={{marginBottom: 30, marginLeft: 0, marginRight: 15, borderRadius: 10, padding: 7}}>

                            <CardItem style={{borderRadius: 20}}>
                                <div>

                                    <h2 style={{fontWeight: '600', fontSize: 18, marginBottom: 0}}>{this.props.item.subject}</h2>
                                    <p style={{color: '#bbb'}}>
                                        {Moment(this.props.item.created_at).format('DD.MM.Y HH:MM')}
                                    </p>
                                    <p style={{marginTop: 10}}>
                                        {this.props.item.text}
                                    </p>
                                </div>
                            </CardItem>

                            {
                                (this.props.item.image.url == null) ? null
                                    :
                                    <CardItem cardBody style={{borderRadius: 20}}>
                                        <div style={{backgroundImage: 'url('+this.props.item.image.url+')', backgroundSize: 'contain', width: '100%', margin: 20, height: 200, flex: 1, borderRadius: 20,backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>
                                    </CardItem>
                            }
                        </Card>
                    </div>
                </div>



            </Modal>
        );
    }
};


export default Board;

import logo from './logo.svg';
import './App.css';
import { Router } from 'react-router-dom';
import history from './services/history';
import Routes from './routes';
import Header from './components/header';

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Header />
        <div className={'content'}>
            <Routes/>
        </div>

      </Router>
    </div>
  );
}

export default App;

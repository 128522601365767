/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {useEffect, useState} from "react";
import axios from 'axios';
import Modal from 'react-modal';

import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ListItem from '@material-ui/core/ListItem';
import TextField from "@material-ui/core/TextField";

class AddDrugs extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={errors: [],  isLoading: true, token: localStorage.getItem('access_token'), child: localStorage.getItem('child'), drugs: [], drugs_id: '', drug: '', frequency: '', medi: ''};

    }

    componentDidMount(){

            const onSuccess = ({data}) => {

                this.setState({
                    isLoading: false,
                    drugs: data.drugs,
                });
            };

            const onFailure = error => {

                if(error.response.status===500){
                    alert("Fehler 500 - ein unbekanntes Problem. Wende  dich an den technischen Support.")
                }else{
                    let errors = []

                    Object.keys(error.response.data.message).map(function(key, val) {

                        errors.push(error.response.data.message[key][0]);
                    });
                    this.setState({errors: errors});

                }
            };

            axios.get('https://app.kitaleicht.com/api/users/alldrugs', {
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(onSuccess)
                .catch(onFailure);

    }

    addToDo() {

            axios.post('https://app.kitaleicht.com/api/users/my/child/' + this.state.child + '/drugs/create',
                {
                    'drugs_id': this.state.drugs_id,
                    'medi': this.state.medi,
                    'frequency': this.state.frequency
                }, {
                    headers: {
                        'Authorization': `Bearer ${this.state.token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                })
                .then((success) => {
                    this.props.onModalCloseDrugs();
                    alert('Medikament wurde erstellt.')
                })
                .catch((errormsg => {
                    if(errormsg.response.status===500){
                        alert("Fehler 500 - ein unbekanntes Problem. Wende  dich an den technischen Support.")
                    }else{
                        let errors = []
                        Object.keys(errormsg.response.data.errors).map(function(key, val) {

                            errors.push(errormsg.response.data.errors[key][0]);
                        });

                        alert('Fehler. ' + errors.join(','))
                    }
                }));

    }

    onValueChangeDrugs (value) {
        this.setState({
            drugs_id : value.target.value
        });
    }

    render() {
        const {loading, list, errors} = this.state;

        const deviceWidth = window.innerWidth / 2 - 20;
        const deviceHeight = window.innerHeight;

        return (
            <Modal
                isOpen={true}
                style={{justifyContent: 'flex-end',margin: 0, backgroundColor: 'transparent'}}

                deviceHeight={deviceHeight}
            >

                <div>
                    <div style={{justifyContent: "flex-end", width: '100%', alignItems: 'flex-end', marginTop: 10, marginBottom: 10, paddingRight: 10, marginLeft: 19}}>
                        <Button onClick={() => this.props.onModalCloseDrugs()} light style={{backgroundColor: '#e9e9e9',width: 40, height: 40, borderRadius: 100,paddingLeft: 12}}>
                            x
                        </Button>
                    </div>
                    <h1 style={{fontWeight: '600', fontSize: 22, padding:20, margin: 0, paddingTop: 10}}>Medikament erstellen</h1>
                    <div
                        className={"container"}
                    >
                        <div>
                            <form className={'form'}>
                                <ListItem style={{marginBottom: 0, marginLeft: 0, paddingLeft: 0}} noBorder>
                                    <div>
                                        <span>Medikament</span>
                                        <div style={{borderRadius: 10, backgroundColor: '#f9f9f9', marginRight: 10, marginBottom: 10, marginTop: 10, color: '#000'}}>

                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={this.state.drugs_id}
                                                style={{width: "100%"}}
                                                onChange={this.onValueChangeDrugs.bind(this)}
                                            >
                                                <MenuItem value={''}>Bitte wählen</MenuItem>
                                                {
                                                    this.state.drugs.map((item) =>
                                                        <MenuItem value={item.id}>{item.name}</MenuItem>
                                                    )
                                                }
                                            </Select>

                                        </div>

                                    </div>
                                </ListItem>

                                {
                                    (this.state.drugs_id == 'other') ?

                                        <div stackedLabel style={{borderBottomWidth: 0}}>
                                            <p>Medikament eintragen</p>
                                            <textarea rowSpan={5} style={{
                                                borderRadius: 10,
                                                backgroundColor: '#f9f9f9',
                                                marginLeft: -8,
                                                marginRight: 10,
                                                marginBottom: 10,
                                                marginTop: 10,
                                                width: '98%'
                                            }} placeholder="Pflichtfeld" value={this.state.medi}
                                              onChange={e => this.setState({ medi: e.target.value })}
                                              />
                                        </div>
                                        :
                                        null
                                }

                                <div stackedLabel style={{border:0, borderBottomWidth: 0}}>
                                    <p style={{color: '#000', fontWeight: '600'}}>Häufigkeit</p>
                                    <TextField value={this.state.frequency}  style={{width: '98%', backgroundColor: '#e9e9e9', paddingLeft: 10,marginRight: 15,  borderRadius: 10, marginTop: 10, marginBottom: 10}}
                                               onChange={e => this.setState({ frequency: e.target.value })} placeholder={'Optional'}/>
                                </div>

                                <Button hasText full warning rounded onClick={() => this.addToDo()} style={{width: 150, marginTop: 0,marginRight: 15, backgroundColor: '#8089C3'}}>
                                    <span style={{color: '#fff'}}>erstellen</span>
                                </Button>

                                {
                                    errors.length == 0 ? null
                                        :
                                        <div style={{
                                            width: '100%',
                                            marginLeft: 0,
                                            marginRight: 0,
                                            backgroundColor: '#B22222',
                                            padding: 10
                                        }}>
                                            {
                                                errors.map((tile, key) => (
                                                    <div stackedLabel style={{borderBottomWidth: 0, marginTop: 5,padding: 5, paddingTop:0}}>
                                                        <span style={{color: '#fff'}}>{tile}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                }
                            </form>

                        </div>
                    </div>
                </div>



            </Modal>
        );
    }
};

export default AddDrugs;

/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow
 */
import React, {Component, Fragment} from 'react';
import axios from 'axios';
import {Button} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";

class More extends React.Component<Props> {
    constructor(props){
        super(props);
        this.state ={ isLoading: false, token: localStorage.getItem('access_token'), };
    }

    logout = async () => {
        localStorage.clear();

        window.location.href = "/";
    };

    delete = async () => {
        const payload = {};

        const onSuccess = async ({data}) => {
            localStorage.clear();
            window.location.href = "/";
        };
        const onFailure = error => {
            console.log(error);
        };

        axios.post('https://app.kitaleicht.com/api/user/profile/delete', payload, {
            headers: {
                'Authorization': `Bearer ${this.state.token}`
            }
        }).then(onSuccess).catch(onFailure);

    };


    render() {

        return (
            <Fragment>
                <div style={{backgroundColor: '#fff', paddingLeft: 10}}>
                    <div style={{marginTop: 40}}>
                        <div style={{borderBottomWidth: 0}}>
                            <div>
                                <h1 style={{fontWeight: '600', fontSize: 32}}>
                                    <img
                                        style={{width: 30, height: 30}}
                                        src={("../assets/icon/mehr_colour.png")}
                                    /> Mehr</h1>
                            </div>
                        </div>
                    </div>

                    <h2 style={{marginLeft: 20, marginBottom: 20, marginTop: 20, fontWeight: '600'}}>Über die Kita</h2>
                    <ListItem button component="a" href={'/kita'}style={{flexDirection: 'row', display:'flex', marginBottom: 10, marginLeft: 0}}>
                        <div style={{backgroundColor: '#e9e9e9', padding: 7, borderRadius: 10}}>
                            <img
                                style={{width: 25, height: 25}}
                                source={("../assets/icon/beirat_colour.png")}
                            />
                        </div>
                        <div style={{fontSize: 20, marginLeft: 10, marginTop: 0}}>Meine Kita</div>
                    </ListItem>
                    <ListItem button component="a" href={'/stunden'}style={{flexDirection: 'row', display:'flex', marginBottom: 10, marginLeft: 0}}>
                        <div style={{backgroundColor: '#e9e9e9', padding: 7, borderRadius: 10}}>
                            <img
                                style={{width: 25, height: 25}}
                                src={("../assets/icon/abholung_colour.png")}
                            />
                            {/* <SvgXml width="25" height="25" xml={beiratIcon} style={{fill:'#000'}} /> */}
                        </div>
                        <div style={{fontSize: 20, marginLeft: 10, marginTop: 0}}>Betreuungszeiten</div>
                    </ListItem>
                    <ListItem button component="a" href={'/beirat'} style={{flexDirection: 'row', display:'flex', marginBottom: 10, marginLeft: 0}}>
                        <div style={{backgroundColor: '#e9e9e9', padding: 7, borderRadius: 10}}>
                            <img
                                style={{width: 25, height: 25}}
                                src={("../assets/icon/beirat_colour.png")}
                            />
                        </div>
                        <div style={{fontSize: 20, marginLeft: 10, marginTop: 0}}>Beirat</div>
                    </ListItem>
                    <ListItem button component="a" href={'/downloads'} style={{flexDirection: 'row', display:'flex', marginBottom: 10, marginLeft: 0}}>
                        <div style={{backgroundColor: '#e9e9e9', padding: 7, borderRadius: 10}}>
                            <img
                                style={{width: 25, height: 25}}
                                src={("../assets/icon/download_colour.png")}
                            />
                        </div>
                        <div style={{fontSize: 20, marginLeft: 10, marginTop: 0}}>Downloadcenter</div>
                    </ListItem>


                    <h2 style={{marginLeft: 20, marginBottom: 20, marginTop: 20, fontWeight: '600'}}>Über die App</h2>
                    <div>
                        <ListItem button component="a" href={'/datenschutz'} tyle={{flexDirection: 'row', display:'flex', marginBottom: 10, marginLeft: 0}}>
                            <div style={{backgroundColor: '#e9e9e9', padding: 7, borderRadius: 10}}>
                                <img
                                    style={{width: 25, height: 25}}
                                    src={("../assets/icon/info.svg")}
                                />
                            </div>
                            <div style={{fontSize: 20, marginLeft: 10, marginTop: 0}}>Datenschutz</div>
                        </ListItem>
                        <ListItem button component="a" href={'/nutzungsbedingungen'} tyle={{flexDirection: 'row', display:'flex', marginBottom: 10, marginLeft: 0}}>
                            <div style={{backgroundColor: '#e9e9e9', padding: 7, borderRadius: 10}}>
                                <img
                                    style={{width: 25, height: 25}}
                                    src={("../assets/icon/info.svg")}
                                />
                            </div>
                            <div style={{fontSize: 20, marginLeft: 10, marginTop: 0}}>Nutzungsbedingungen</div>
                        </ListItem>
                        <ListItem button component="a" href={'/impressum'} tyle={{flexDirection: 'row', display:'flex', marginBottom: 10, marginLeft: 0}}>
                            <div style={{backgroundColor: '#e9e9e9', padding: 7, borderRadius: 10}}>
                                <img
                                    style={{width: 25, height: 25}}
                                    src={("../assets/icon/info.svg")}
                                />
                            </div>
                            <div style={{fontSize: 20, marginLeft: 10, marginTop: 0}}>Impressum</div>
                        </ListItem>
                    </div>

                </div>
            </Fragment>
        );
    }
};

export default More;
